<div class="jumbotron">
    <h1 class="display-4">Rollenverwaltung</h1>
    <h2 class="">{{event.title}}</h2>
    <h3 class="">{{registration.title}}</h3>
  </div>
  <div class="col-md-10 col-md-offset-1">
    <div class="well well bs-component">
        <form novalidate class="form-horizontal" (ngSubmit)="assignRole(assignRoleForm.value)" #assignRoleForm="ngForm" enctype="multipart/form-data">
           <fieldset>
                <div class="form-group">
                <label for="title" class="col-lg-2 control-label">Benutzer</label>
                <div class="col-lg-10">
                    <ngx-select-dropdown [instanceId]="'userDropdown'" name="user" [config]="config" ngModel #user="ngModel" [options]="users" required></ngx-select-dropdown>

                    <!-- <div *ngIf="user.errors?.required && user.dirty" class="alert alert-danger">
                    Benutzer ist ein Pflichtfeld
                    </div> -->
                </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-10 col-lg-offset-2">
                    <button type="submit" class="btn btn-primary" [disabled]="assignRoleForm.invalid">Berechtigung zuweisen</button>
                    </div>
                </div>
           </fieldset>
        </form>
    </div>
    <br />
    <div class="col-md-12 col-md-offset-1">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Vorname</th>
              <th>Nachname</th>
              <th>E-Mail Adresse</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let role_assignment of role_assignments | async">
              <td>{{role_assignment.user}} </td>
              <td>{{role_assignment.givenname}} </td>
              <td>{{role_assignment.email}} </td>
              <td>
                <a [routerLink]="['/admin/event/', event.id, 'registration', 'delete', registration.id]" class="btn mx-1 btn-danger"> <i class="fa fa-trash"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
  </div>
  
  