import { Component, OnInit } from '@angular/core';
import {EventService} from '../../../services/event.service';
import { Router} from '@angular/router';
import { NotifyService } from '../../../services/notify.service';


@Component({
  selector: 'app-admin-event-create',
  templateUrl: './admin-event-create.component.html',
  styleUrls: ['./admin-event-create.component.css']
})
export class AdminEventCreateComponent implements OnInit {
status: any;
filedata: any;

fileEvent(e) {
  this.filedata = e.target.files[0];
}

  constructor(
    private eventService: EventService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  ngOnInit() {
  }

  createEvent(event) {
   this.eventService.addEvent(event)
   .subscribe(
     event => {
      var file = new FormData();
      file.append('imageLink', this.filedata)

      this.eventService.uploadEventImage(event["data"]["id"], file).subscribe(
        eventImage => {
         this.notifyService.snotifySuccess("Die Veranstaltung wurde erfolgreich angelegt!")
         this.router.navigate(['/admin/events']);
        },
        error => {
         this.notifyService.snotifyError("Beim Hochladen des Bildes ist ein Fehler aufgetreten!")
         this.router.navigate(['/admin/event/edit', event["data"]["id"]]);
         console.log(<any>error)
       }
      )
     },
     error => {
      this.notifyService.snotifyError("Beim Anlegen der Veranstaltung ist ein Fehler aufgetreten!")
      console.log(<any>error)
    }
   );
 }


}
