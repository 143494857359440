<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">ANMELDUNG</h1>
    <h2 class="display-6">{{event.title | uppercase}}</h2>
  </div>
  <img src="{{event.imageLink}}" alt="">
</div>
<div class="alert alert-info">
  Liebe Leitenden, <br/>
  <br/>
  herzlich Willkommen beim Anmeldetool für das Ruhrjamb 2024 des DV Essen. Wir freuen uns sehr, dass ihr mit dabei sein möchtet. <br/><br />
  Die Anmeldung ist in zwei Teile gegliedert. Im ersten Teil müsst ihr eure Stammesdaten angeben. Im zweiten Teil fragen wir die Daten der Teilnehmenden ab. Um diese zu erheben haben wir für euch einen Vordruck für die Teilnehmendenanmeldung geschrieben. Tragt dort bitte eure Stammesdaten ein und legt das Datum fest, bis wann ihr die ausgefüllten Anmeldungen zurück bekommen wollt. Bis zum 25.06.2024 müssen alle Daten der Teilnehmenden eingetragen werden.
  Ihr könnt die Anmeldung jeder Zeit pausieren und zu einem späteren Zeitpunkt weiter bearbeiten. Denkt jedoch daran, sie rechtzeitig abzuschicken. <br />
  <br />
  Wir freuen uns auf einen wundervollen Tag mit euch.<br />
  <br />
  Gut Pfad<br />
  Die Projektleitung des Ruhrjambs 2024 des DV Essen<br />
  <br />
  <h6>Wichtige Dokumente</h6>
  <ul>
    <li>
      <a href="assets/ruhrjamb2024/Anmeldung einzelne Teilnehmende.pdf" target="_blank">Vorlage Anmeldung Teilnehmende</a>
    </li>
  </ul>
  <p class="text-center font-weight-bold">Den gesamten Teilnehmenden-Beitrag (15,00 € pro Person bzw. für den Bezirk Sauerland 20,00 € pro Person) <br/>Eures Stammes überweist Ihr bitte auf folgendes Konto:</p>
  <p class="text-center">Empfänger: Pfadfinderförderwerk im Bistum Essen e.V.<br />
    Bankverbindung: Pax Bank eG Essen - GENODED1PAX<br />
    IBAN: DE59 3706 0193 2000 4150 25<br />
    Verwendungszweck: Ruhrjamb (Stammesname)<br />
  </p>  
  <br />
</div>

<form novalidate class="form-horizontal" (ngSubmit)="AddOrUpdateSubscription(AddOrUpdateSubscriptionForm.value)" #AddOrUpdateSubscriptionForm="ngForm" >
  <fieldset disabled="disabled">
  <div class="card mt-4">
      <div class="card-header">Stammesdaten / Rechnungsadresse </div>
      <div class="card-body">
          <div class="form-group">
              <label for="stamm_strasse" class="col-lg-10 control-label">Straße</label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="stamm_strasse" name="stamm_strasse" placeholder="Straße" [(ngModel)]="subscription.stamm_strasse" #stamm_strasse="ngModel" required>
                <div *ngIf="stamm_strasse.errors?.required && stamm_strasse.dirty" class="alert alert-danger">
                  Straße ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="stamm_hausnummer" class="col-lg-10 control-label">Hausnummer</label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="stamm_hausnummer" name="stamm_hausnummer" placeholder="Hausnummer" [(ngModel)]="subscription.stamm_hausnummer" #stamm_hausnummer="ngModel" required>
                <div *ngIf="stamm_hausnummer.errors?.required && stamm_hausnummer.dirty" class="alert alert-danger">
                  Hausnummer ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="stamm_plz" class="col-lg-10 control-label">PLZ</label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="stamm_plz" name="stamm_plz" placeholder="PLZ" [(ngModel)]="subscription.stamm_plz" #stamm_plz="ngModel" required>
                <div *ngIf="stamm_plz.errors?.required && stamm_plz.dirty" class="alert alert-danger">
                  PLZ ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="stamm_ort" class="col-lg-10 control-label">Ort</label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="stamm_ort" name="stamm_ort" placeholder="Ort" [(ngModel)]="subscription.stamm_ort" #stamm_ort="ngModel" required>
                <div *ngIf="stamm_ort.errors?.required && stamm_ort.dirty" class="alert alert-danger">
                  Ort ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="stamm_email" class="col-lg-10 control-label">E-Mail</label>
              <div class="col-lg-10">
                <input type="email" class="form-control" id="stamm_email" name="stamm_email" placeholder="E-Mail" [(ngModel)]="subscription.stamm_email" #stamm_email="ngModel" required>
                <div *ngIf="stamm_email.errors?.required && stamm_email.dirty" class="alert alert-danger">
                  E-Mail ist ein Pflichtfeld
                </div>
              </div>
            </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header">Anreiseoptionen</div>
      <div class="card-body">
        <div class="form-group">
          <label for="sammelAnreise" class="col-lg-3 control-label">Stamm reist gesammelt an</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="sammelAnreise" name="sammelAnreise" [ngModel]="null" [(ngModel)]="subscription.sammelAnreise" #sammelAnreise="ngModel" required>
              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
              <option *ngFor="let sammelAnreiseOption of sammelAnreiseOptions" value="{{sammelAnreiseOption.name}}">{{sammelAnreiseOption.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group" *ngIf="subscription.sammelAnreise == 'Nein'">

          <label for="sonderAnreise" class="col-lg-3 control-label">Stufen reisen gesondert an</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="sonderAnreise" name="sonderAnreise" [ngModel]="null" [(ngModel)]="subscription.sonderAnreise" #sonderAnreise="ngModel" [required]="subscription.sammelAnreise == 'Nein'">
              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
              <option *ngFor="let sonderAnreiseOption of sonderAnreiseOptions" value="{{sonderAnreiseOption.name}}">{{sonderAnreiseOption.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="barrierefreiheit" class="col-lg-10 control-label">Für die Teilnahme sind folgende Hilfsangebote erforderlich</label>
          <div class="col-lg-10">
            <textarea class="form-control" rows="5" id="barrierefreiheit" name="barrierefreiheit" placeholder="z.B. Gebärdendolmetscher, barrierearme Wege, barrierefreie Toiletten etc." [(ngModel)]="subscription.barrierefreiheit" #barrierefreiheit="ngModel"></textarea>
            <div *ngIf="barrierefreiheit.errors?.required && barrierefreiheit.dirty" class="alert alert-danger">
              Barrierefreiheit ist ein Pflichtfeld
            </div>
          </div>
        </div>
          
      </div>

    </div>
<br />
</fieldset>

  <div class="form-group">
         <div class="col-lg-10 col-lg-offset-2">
           <a [routerLink]="['/events']" class="btn btn-default">Zurück</a>
           <button type="submit" class="btn btn-primary" [disabled]="AddOrUpdateSubscriptionForm.invalid || event.active == 0">Speichern</button>
         </div>
       </div>

</form>

<hr />



<div class="col-md-12 col-md-offset-1">
  <div>
    <a [routerLink]="['/events', 'custom', 'ruhrjamb2024', 'addTN']" class="btn btn-success" *ngIf="event.active == 1 && subscription_id != undefined && freeSeats > 0">Neue/n Teilnehmende/n anlegen</a>
    <a [routerLink]="" (click)="export()" class="btn btn-default float-right" role="button" aria-expanded="false" *ngIf="subscription_id != undefined"><i class="fa fa-file-excel-o"></i> Export Excel</a>

  </div>
</div>
<br />
<div class="col-md-12 col-md-offset-1" *ngIf="subscription_id != undefined">
  <div class="table-responsive">
    <table class="table table-users">
      <thead>
        <tr>
          <th>Nachname</th>
          <th>Vorname</th>
          <th>TN / Leitung</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tn of tns">
          <td>{{tn.nachname}}</td>
          <td>{{tn.vorname}}</td>
          <td>{{tn.tn_leitung | uppercase }}</td>
          <td>
           <a [routerLink]="['/events/custom/ruhrjamb2024/editTN', tn.id]" class="btn btn-warning btn-users" *ngIf="event.active == 1"> Bearbeiten</a>
           <a [routerLink]="['/events/custom/ruhrjamb2024/delTN', tn.id]" class="btn btn-danger btn-users mx-2" *ngIf="closed == 0"> Löschen</a>
         </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>