import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { Router, ActivatedRoute } from '@angular/router';

import { Settings } from '../classes/settings';
import { Observable ,  of } from 'rxjs';
import { flatMap, catchError, map, share } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import {NotifyService} from '../services/notify.service';

@Injectable()
export class BackendService {

  redirectUrl: string;
  private options: any;
  private jwt: JwtHelperService;
  private loginRequest: Observable<boolean> = null;
  private tokenRequest: Observable<boolean> = null;
  private forgotPasswordRequest: Observable<boolean> = null;
  private resetPasswordRequest: Observable<boolean> = null;

  constructor(
    private http: HttpClient,
    private notifyService: NotifyService,
    private route: ActivatedRoute,
  ) {
    this.setHeader()
}

private setHeader() {
    this.options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(Settings.authAccessTokenName)
        })
    };

    this.redirectUrl = this.route.snapshot.queryParams['returnUrl'] || '/events';
    this.jwt = new JwtHelperService();
}

get istAngemeldet(): Observable<boolean> {
      return this.refreshToken();
  }

   delete(url: string): Observable<any> {
       return this.refreshToken().pipe(
           flatMap((angemeldet: boolean) => {
               if (angemeldet) {
                    this.setHeader()
                   return this.http.delete(Settings.appURL + Settings.apiPath + url, this.options);
               } else {
                   return ErrorObservable.create(new Error('Sitzung abgelaufen. Bitte erneut anmelden!'));
               }
           }),
           catchError((error: any) => {
               return ErrorObservable.create(error);
           })
       );
   }


  download(url: string, parameter: any, dateityp: string): Observable<any> {
      return this.refreshToken().pipe(
          flatMap((angemeldet: boolean) => {
              if (angemeldet) {
                this.setHeader()
                  let options: any;
                  if (dateityp === 'pdf') {
                      options = this.options
                      options = {
                          headers: new HttpHeaders({
                              responseType: 'blob',
                              Accept: 'application/x-download'
                          })
                      };
                      options.responseType = 'blob';
                  } else if (dateityp === 'csv') {
                      options = this.options
                      options.responseType = 'text';
                  } else if (dateityp === 'zip') {
                    options = {
                        headers: new HttpHeaders({
                            responseType: 'arraybuffer',
                            Accept: 'application/octet-stream'
                        })
                    };
                    options.responseType = 'arraybuffer';
                  } else {
                      options = this.options;
                  }
                  return this.http.post(Settings.appURL + Settings.apiPath + url, parameter, options);
              } else {
                  return ErrorObservable.create(new Error('Sitzung abgelaufen. Bitte erneut anmelden!'));
              }
          }),
          catchError((error: any) => {
              return ErrorObservable.create(error);
          })
      );
  }



get(url: string): Observable<any> {
       return this.refreshToken().pipe(
           flatMap((angemeldet: boolean) => {
               if (angemeldet) {
                    this.setHeader()
                   return this.http.get(Settings.appURL + Settings.apiPath + url, this.options);
               } else {
                   return ErrorObservable.create(new Error('Sitzung abgelaufen. Bitte erneut anmelden!'));
               }
           }),
           catchError((error: any) => {
               return ErrorObservable.create(error);
           })
       );
   }


     login(benutzer: string, passwort: string): Observable<boolean> {
       if (this.loginRequest !== null) {
           return this.loginRequest;
       } else {
           const daten = {
               'username': benutzer,
               'password': passwort
           };
           this.loginRequest = this.http.post(Settings.appURL + Settings.apiPath + 'public/login', daten).pipe(
                   map((data: any) => {
                       this.loginRequest = null;
                       //const token: any = data.json();
                       //console.log(token)

                       localStorage.setItem(Settings.authAccessTokenName, data.access_token);
                       localStorage.setItem(Settings.authRefreshTokenName, data.refresh_token);
                       this.notifyService.snotifySuccess("Erfolgreich eingeloggt!")

                       return true;
                   }),
                   catchError((error: any) => {
                       this.loginRequest = null;
                       if (error.status === 401) {
                         this.notifyService.snotifyError("Fehler beim Anmelden: Anmeldedaten falsch!")
                       } else if (error.status === 500) {
                         this.notifyService.snotifyError("Fehler beim Anmelden: Serverfehler!")
                       } else {
                         this.notifyService.snotifyError("Fehler beim Anmelden: Unbekannter Fehler!")
                       }
                       return of(false);
                   })
               );

               return this.loginRequest;
           }

       }

       logout(): Observable<any> {
       return this.get('logout').pipe(
           map(res => {
               localStorage.removeItem(Settings.authAccessTokenName);
               localStorage.removeItem(Settings.authRefreshTokenName);
               this.notifyService.snotifySuccess("Erfolgreich abgemeldet!")
               return res;
           })
       );
   }

   forgotPassword(email: string): Observable<boolean> {
    if (this.forgotPasswordRequest !== null) {
        return this.forgotPasswordRequest;
    } else {
        const daten = {
            'email': email,
        };
        this.forgotPasswordRequest = this.http.post(Settings.appURL + Settings.apiPath + 'public/forgot-password', daten).pipe(
                map((data: any) => {
                    this.forgotPasswordRequest = null;
                    //const token: any = data.json();
                    //console.log(token)

                    this.notifyService.snotifySuccess("Der Link zum Passwort zurücksetzen wurde per Mail versandt!")

                    return true;
                }),
                catchError((error: any) => {
                    this.forgotPasswordRequest = null;
                    if (error.status === 401) {
                      this.notifyService.snotifyError("Fehler beim Passwort zurücksetzen: E-Mail Adresse unbekannt!")
                    } else if (error.status === 500) {
                      this.notifyService.snotifyError("Fehler beim Passwort zurücksetzen: Serverfehler!")
                    } else {
                      this.notifyService.snotifyError("Fehler beim Passwort zurücksetzen: Unbekannter Fehler!")
                    }
                    return of(false);
                })
            );

            return this.forgotPasswordRequest;
        }

    }

    resetPassword(email: string, password: string, password_confirmation: string, token: string): Observable<boolean> {
        if (this.resetPasswordRequest !== null) {
            return this.resetPasswordRequest;
        } else {
            const daten = {
                'email': email,
                'password': password,
                'password_confirmation': password_confirmation,
                'token': token
            };
            this.resetPasswordRequest = this.http.post(Settings.appURL + Settings.apiPath + 'public/reset-password', daten).pipe(
                    map((data: any) => {
                        this.resetPasswordRequest = null;
                        //const token: any = data.json();
                        //console.log(token)
    
                        this.notifyService.snotifySuccess("Das Passwort wurde erfolgreich zurückgesetzt!")
    
                        return true;
                    }),
                    catchError((error: any) => {
                        this.resetPasswordRequest = null;
                        if (error.status === 401) {
                          this.notifyService.snotifyError("Fehler beim Passwort zurücksetzen: E-Mail Adresse unbekannt!")
                        } else if (error.status === 500) {
                          this.notifyService.snotifyError("Fehler beim Passwort zurücksetzen: Serverfehler!")
                        } else {
                          this.notifyService.snotifyError("Fehler beim Passwort zurücksetzen: Unbekannter Fehler!")
                        }
                        return of(false);
                    })
                );
    
                return this.resetPasswordRequest;
            }
    
        }

   post(url: string, parameter: any): Observable<any> {
       return this.refreshToken().pipe(
           flatMap((angemeldet: boolean) => {
               if (angemeldet) {
                this.setHeader()
                   return this.http.post(Settings.appURL + Settings.apiPath + url, parameter, this.options);
               } else {
                   return ErrorObservable.create(new Error('Sitzung abgelaufen. Bitte erneut anmelden!'));
               }
           }),
           catchError((error: any) => {
               return ErrorObservable.create(error);
           })
       );
   }

   upload(url: string, parameter: any): Observable<any> {
    return this.refreshToken().pipe(
        flatMap((angemeldet: boolean) => {
            if (angemeldet) {
                let options = {
                    headers: new HttpHeaders({
                        contentType: "multipart/form-data",
                        accept: "application/json",
                        'Authorization': 'Bearer ' + localStorage.getItem(Settings.authAccessTokenName)
                    })
                };
                console.log(options)
                return this.http.post(Settings.appURL + Settings.apiPath + url + '/upload', parameter, options);
            } else {
                return ErrorObservable.create(new Error('Sitzung abgelaufen. Bitte erneut anmelden!'));
            }
        }),
        catchError((error: any) => {
            return ErrorObservable.create(error);
        })
    );
}

   put(url: string, parameter: any): Observable<any> {
       return this.refreshToken().pipe(
           flatMap((angemeldet: boolean) => {
               if (angemeldet) {
                this.setHeader()
                   return this.http.put(Settings.appURL + Settings.apiPath + url, parameter, this.options);
               } else {
                   return ErrorObservable.create(new Error('Sitzung abgelaufen. Bitte erneut anmelden!'));
               }
           }),
           catchError((error: any) => {
               return ErrorObservable.create(error);
           })
       );
   }







   private refreshToken(): Observable<boolean> {
       const access_token = localStorage.getItem(Settings.authAccessTokenName);
       if (access_token === null) {
           return of(false);
    }       if ((access_token !== null) && (!this.jwt.isTokenExpired(access_token))) {
           return of(true);
       } else {
           if (this.tokenRequest !== null) {
               return this.tokenRequest;
           } else {
               const refresh_token = localStorage.getItem(Settings.authRefreshTokenName);
               if (!!(refresh_token === null)) {
                   return of(false);
               }

               const daten = {
                   'refresh_token': refresh_token
               };
               this.tokenRequest = this.http.post(Settings.appURL + Settings.apiPath + 'public/login/refresh', daten).pipe(
                   map((data: any) => {
                       this.tokenRequest = null;
                    //   const token = data.json();

                       localStorage.setItem(Settings.authAccessTokenName, data.access_token);
                       localStorage.setItem(Settings.authRefreshTokenName, data.refresh_token);
                       return true;
                   }),
                   catchError((error: any) => {
                       localStorage.removeItem(Settings.authAccessTokenName);
                       localStorage.removeItem(Settings.authRefreshTokenName);
                    //   this.nachrichtenService.fehler('Fehler beim Aufruf', 'Sitzung abgelaufen (' + error.status + ').');

                       this.tokenRequest = null;

                       return of(false);
                   }),
                   share()
               );
               return this.tokenRequest;
           }
       }
   }



}
