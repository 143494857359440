import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Settings } from '../classes/settings';
import { Event } from '../models/event';
import { EventService } from '../services/event.service';
import { RegistrationService } from '../services/registration.service';
import { Ruhrjamb2024Service } from '../services/ruhrjamb2024.service';

@Component({
  selector: 'app-events-dashboard',
  templateUrl: './events-dashboard.component.html',
  styleUrls: ['./events-dashboard.component.css']
})
export class EventsDashboardComponent implements OnInit {

  events: Observable<Event[]>;
  backendurl: any;
  ruhrjamb_free: any;
  constructor(
    private eventService: EventService,
    private registrationService: RegistrationService,
    private ruhrjambService: Ruhrjamb2024Service
) { }


  ngOnInit() {
    this.ruhrjambService.getFreeSeats().subscribe(
      data => {
        console.log(data)
        this.ruhrjamb_free = data
      }
    );
    this.events = this.eventService.getActiveEvents();
  }

}
