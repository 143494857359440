<div class="jumbotron">
  <h1 class="display-4">Veranstaltungen</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <div>
    <a [routerLink]="['/admin/event/create']" class="btn btn-success">Neue Veranstaltung anlegen</a>
    <div class="form-check float-right">
      <input type="checkbox" class="form-check-input" (change)="loadAll($event)"> 
      <label class="form-check-label" for="defaultCheck1">
        Inaktive Veranstaltungen einblenden
      </label>
    </div>
  </div>


</div>
<br />
<div class="col-md-12 col-md-offset-1">
  <div class="form-group">
    <div class="input-group row">
      <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
    </div>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Logo</th>
          <th>Veranstaltung</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of events | async | filter:{title: filter}">
          <td><img src="{{backendurl}}images/{{event.imageLink}}" width="200"/></td>
          <td>{{event.title}} </td>
          <td>
            <a [routerLink]="['/admin/event/', event.id, 'registrations']" class="btn mx-1 btn-info"> <i class="fa fa-eye"></i></a>
            <a [routerLink]="['/admin/event/edit', event.id]" class="btn mx-1 btn-warning"> <i class="fa fa-pencil"></i></a>
            <a [routerLink]="['/admin/event/delete', event.id]" class="btn mx-1 btn-danger"> <i class="fa fa-trash"></i></a>
            <a [routerLink]="['/admin/event/roles', event.id]" class="btn mx-1 btn-dark disabled"> <i class="fa fa-key disabled"></i></a>

          </td>
        </tr>
      </tbody>
    </table>
    
    <h6>Statische Veranstaltungen</h6>

    <table class="table">
      <thead>
        <tr>
          <th>Logo</th>
          <th>Veranstaltung</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><img src="{{backendurl}}/assets/images/ruhrjamb2024.png" width="200" alt="Ruhrjamb2024"></td>
          <td>Ruhrjamb 2024</td>
          <td>
            <a [routerLink]="['/admin/event/custom/ruhrjamb2024/registrations']" class="btn btn-info"> <i class="fa fa-eye"></i></a>
          </td>
        </tr>
        <tr>
          <td><img src="{{backendurl}}/assets/images/roverdu2023.png" width="200" alt="Rover-Diözesanunternehmen"></td>
          <td>Roverlænd</td>
          <td>
            <a [routerLink]="['/admin/event/custom/rover_du_2023/registrations']" class="btn btn-info"> <i class="fa fa-eye"></i></a>
          </td>
        </tr>
        <tr>
          <td><img src="{{backendurl}}/assets/images/juffidu2023.png" width="200" alt="Juffi-Diözesanunternehmen"></td>
          <td>Juffi-Diözesansommerlager</td>
          <td>
            <a [routerLink]="['/admin/event/custom/juffi_du_2023/registrations']" class="btn btn-info"> <i class="fa fa-eye"></i></a>
          </td>
        </tr>
        <tr>
          <td><img src="https://anmeldung.dpsg-essen.de/assets/images/pfadi_du_2022.png" width="200" alt="Pfadi-Diözesanunternehmen"></td>
          <td>Pfadi-Diözesanunternehmen</td>
          <td>
            <a [routerLink]="['/admin/event/custom/pfadi_du_2022/registrations']" class="btn btn-info"> <i class="fa fa-eye"></i></a>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
