<div class="jumbotron">
  <h1 class="display-4">Benutzer</h1>
</div>


<div class="col-md-10 col-md-offset-1">
  <div>
    <a [routerLink]="['/admin/user/create']" class="btn btn-success">Neuen Benutzer anlegen</a>
  </div>
</div>
<br />
<div class="col-md-12 col-md-offset-1">
  <div class="form-group">
    <div class="input-group row">
      <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-users">
      <thead>
        <tr>
          <th>Nachname</th>
          <th>Vorname</th>
          <th>E-Mail Adresse</th>
          <th>Admin</th>
          <th>Bezirk</th>
          <th>Stamm</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users | async | filter:{givenname: filter, surname: filter, email: filter, bezirktitle: filter, stammtitle: filter}">
          <td>{{user.givenname}}</td>
          <td>{{user.surname}}</td>
          <td>{{user.email}}</td>
          <td class="text-center"><input type="checkbox" name="admin" value="admin" [checked]="user.admin == 1" disabled></td>
          <td>{{user.bezirktitle}}</td>
          <td>{{user.stammtitle}}</td>
          <td class="min-vw-100">
            <a [routerLink]="['/admin/user/edit', user.id]" class="btn btn-warning btn-users mx-2"> <i class="fa fa-pencil"></i></a>
            <a [routerLink]="['/admin/user/delete', user.id]" class="btn btn-danger btn-users"> <i class="fa fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
