
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Event } from '../models/event';
import { Settings } from '../classes/settings';
import { BackendService } from '../services/backend.service';

@Injectable()
export class EventService {
  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addEvent(event: Object): Observable<Event[]> {
    return this.backendService.post('events', event)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  uploadEventImage(id: any, image: Object): Observable<any> {
    return this.backendService.upload('events/' + id, image)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  getEvents(): Observable<any> {
  return this.backendService.get('events')
    .map((response) => response);
  }

  getActiveEvents(): Observable<any> {
  return this.http.get(Settings.appURL + Settings.apiPath + 'public/activeEvents')
    .map((response) => response);
  }

  getEvent(event: string): Observable<any> {
  return this.http.get(Settings.appURL + Settings.apiPath + 'public/events/' + event)
    .map((response) => response);
  }

  updateEvent(event: Object): Observable<Event[]> {
   const apiUrl = 'events';
   const url = `${apiUrl}/${event['id']}`;
   return this.backendService.put(url, event)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
 }

 deleteEvent(event: Object): Observable<Event[]> {
  const apiUrl = 'events';
  const url = `${apiUrl}/${event}`;
  return this.backendService.delete(url)
     .map((response) => response)
    .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
}


}
