import {Routes, RouterModule} from '@angular/router';

import { EventsDashboardComponent } from './events-dashboard.component';
import { EventsRegistrations } from './events-registrations/events-registrations.component';
import { EventsRegistrationsSubscriptionComponent } from './events-registrations-subscription/events-registrations-subscription.component';
import { EventsRegistrationSubscriptionAddComponent } from './events-registrations-subscription/events-registration-subscription-add/events-registration-subscription-add.component';
import { EventsRegistrationSubscriptionEditComponent } from './events-registrations-subscription/events-registration-subscription-edit/events-registration-subscription-edit.component';
import { EventsRegistrationSubscriptionDelComponent } from './events-registrations-subscription/events-registration-subscription-del/events-registration-subscription-del.component';
import { Ruhrjamb2024Component } from './custom/ruhrjamb2024/ruhrjamb2024.component';
import { Ruhrjamb2024AddTNComponent } from './custom/ruhrjamb2024/ruhrjamb2024-add-tn/ruhrjamb2024-add-tn.component';
import { Ruhrjamb2024EditTNComponent } from './custom/ruhrjamb2024/ruhrjamb2024-edit-tn/ruhrjamb2024-edit-tn.component';
import { Ruhrjamb2024DelTNComponent } from './custom/ruhrjamb2024/ruhrjamb2024-del-tn/ruhrjamb2024-del-tn.component';
import { Ruhrjamb2024AddStaffComponent } from './custom/ruhrjamb2024/ruhrjamb2024-add-staff/ruhrjamb2024-add-staff.component';
import { AuthGuardService } from '../services/auth.guard.service';


export const eventsRoutes: Routes = [
  { path: '', component: EventsDashboardComponent},
  { path: ':event_id', redirectTo:':event_id/registrations', pathMatch: 'full'},
  { path: ':event_id/registrations', component: EventsRegistrations, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription', component: EventsRegistrationsSubscriptionComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/add', component: EventsRegistrationSubscriptionAddComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/:subscription_id/edit', component: EventsRegistrationSubscriptionEditComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/:subscription_id/del', component: EventsRegistrationSubscriptionDelComponent, canActivate: [AuthGuardService]},
  { path: 'custom/ruhrjamb2024/registrations', component: Ruhrjamb2024Component, canActivate: [AuthGuardService]},
  { path: 'custom/ruhrjamb2024/addTN', component: Ruhrjamb2024AddTNComponent, canActivate: [AuthGuardService]},
  { path: 'custom/ruhrjamb2024/addStaff', component: Ruhrjamb2024AddStaffComponent, canActivate: [AuthGuardService]},
  { path: 'custom/ruhrjamb2024/editTN/:tn_id', component: Ruhrjamb2024EditTNComponent, canActivate: [AuthGuardService]},
  // { path: 'custom/ruhrjamb2024/delTN/:tn_id', component: Ruhrjamb2024DelTNComponent, canActivate: [AuthGuardService]},

];
