<div class="jumbotron">
  <h1 class="display-4">Vorlagen</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <div>
    <a [routerLink]="['/admin/template/create']" class="btn btn-success">Neue Vorlage anlegen</a>
  </div>
</div>
<br />
<div class="col-md-12 col-md-offset-1">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Vorlage</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let template of templates | async">
          <td>{{template.title}} </td>
          <td>
            <a [routerLink]="['/admin/template/', template.id]" class="btn btn-info"> <i class="fa fa-eye"></i></a>
            <a [routerLink]="['/admin/template/edit', template.id]" class="btn btn-warning mx-2"> <i class="fa fa-pencil"></i></a>
            <a [routerLink]="['/admin/template/delete', template.id]" class="btn btn-danger" > <i class="fa fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
