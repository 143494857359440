<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Neue Staff-Anmeldung</h1>
    <h2 class="display-6">{{event.title | uppercase}}</h2>
  </div>
  <img src="{{event.imageLink}}" alt="">
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="addStaff(addStaffForm.value)" #addStaffForm="ngForm">
      <fieldset [disabled]="subscription_id != undefined">
        <div class="card">
          <div class="card-header">Allgemeine Angaben</div>
          <div class="card-body">
            <div class="form-group">
              <label for="status" class="col-lg-10 control-label">Status</label>
              <div class="col-lg-10">
                  <select class="form-control" rows="2" id="status" name="status" [ngModel]="null" [(ngModel)]="staff.status" #status="ngModel" required>
                      <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                      <option value="leitung">Leiter:in ab 18</option>
                      <option value="ma">Mitarbeiter:in</option>
                      <option value="sonstiges">sonstiges Stammesmitglied</option>
                  </select>
              </div>
          </div>
          <div class="form-group">
            <label for="tage" class="col-lg-3 control-label">Verfügbare Tage</label>
              <div class="col-lg-10 row" *ngFor="let tageOption of tageOptions">
                <div class="col-lg-1">
                  <input type="checkbox" id="tageOptions" name="tage" value="{{tageOption.name}}" (change)="updateCheckedtage(tageOption.name, $event)">
                  </div>
                  <div class="col-lg-11">
                  {{tageOption.name}}
                  </div>
            </div>
          </div>
        </div>
        </div>
        <div class="card">
          <div class="card-header">Lebensmittelabfragen</div>
          <div class="card-body">
            <div class="alert alert-secondary">
              Die Verpflegung während des Ruhrjambs ist tierfleischfrei - sollte eine vegane Verpflegung gewünscht sein, gebt diese bitte beim Verpflegungswunsch an!
            </div>
            <div class="form-group">
              <label for="lebensmVerpf" class="col-lg-3 control-label">Verpflegung</label>
              <div class="col-lg-10 row" *ngFor="let lebensmVerpfOption of lebensmVerpfOptions">
                <div class="col-lg-1">
                  <input type="checkbox" id="lebensmVerpfOptions" name="lebensmVerpf" value="{{lebensmVerpfOption.name}}" (change)="updateCheckedlebensmVerp(lebensmVerpfOption.name, $event)">
                  </div>
                  <div class="col-lg-11">
                  {{lebensmVerpfOption.name}}
                  </div>
            </div>
            </div>
            <div class="form-group">
              <label for="lebensmUnvert" class="col-lg-3 control-label">Unverträglichkeiten</label>
                <div class="col-lg-10 row" *ngFor="let lebensmUnvertOption of lebensmUnvertOptions">
                  <div class="col-lg-1">
                    <input type="checkbox" id="lebensmUnvertOptions" name="lebensmUnvert" value="{{lebensmUnvertOption.name}}" (change)="updateCheckedlebensmUnvert(lebensmUnvertOption.name, $event)">
                    </div>
                    <div class="col-lg-11">
                    {{lebensmUnvertOption.name}}
                    </div>
              </div>
            </div>
            <div class="form-group">
              <label for="lebensmSonsUnvert" class="col-lg-12 control-label">Sonstige Unverträglichkeiten</label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="lebensmSonsUnvert" name="lebensmSonsUnvert" placeholder="Sonstige Unverträglichkeiten" [(ngModel)]="staff.lebensmSonsUnvert" #lebensmSonsUnvert="ngModel">
                <div *ngIf="lebensmSonsUnvert.errors?.required && lebensmSonsUnvert.dirty" class="alert alert-danger">
                  Sonstige Unverträglichkeiten ist ein Pflichtfeld
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">Kompetenzen</div>
          <div class="card-body">
              <div class="form-group">
                      <label for="gesundheitszeugnis">
                        Gesundheitszeugnis für den Umgang mit Lebensmitteln
                      </label>
                      <div class="form-group">
                        <div class="col-lg-10">
                          <select class="form-control" rows="2" id="gesundheitszeugnis" name="gesundheitszeugnis" [ngModel]="null" [(ngModel)]="staff.gesundheitszeugnis" #gesundheitszeugnis="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>                        
                      </div>
                    </div>
              </div>
              <hr />
              <div class="form-group">
                <label for="fuehrerschein" class="col-lg-6 control-label">Führer- / Anhängerschein</label>
                <div class="col-lg-10 row" *ngFor="let fuehrerscheinOption of fuehrerscheinOptions">
                  <div class="col-lg-1">
                    <input type="checkbox" id="fuehrerscheinOptions" name="fuehrerschein" value="{{fuehrerscheinOption.name}}" (change)="updateCheckedFuehrerschein(fuehrerscheinOption.name, $event)">
                    </div>
                    <div class="col-lg-11">
                    {{fuehrerscheinOption.name}}
                    </div>
              </div>
              </div>
              <hr />
              <div class="form-group">
                <label for="elektrofachkraft">
                  Elektrofachkraft für festgelegte, gleichartige, sich wiederholende Tätigkeiten (Kleiner Elektroschein)
                </label>
                <div class="form-group">
                  <div class="col-lg-10">
                    <select class="form-control" rows="2" id="elektrofachkraft" name="elektrofachkraft" [ngModel]="null" [(ngModel)]="staff.elektrofachkraft" #elektrofachkraft="ngModel" required>
                      <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                      <option value="1">Ja</option>
                      <option value="0">Nein</option>
                  </select>                        
                </div>
              </div>
        </div>
        <div class="form-group">
          <label for="fernpilotenzeugnis">
            Fernpilotenzeugnis (Drohnenführerschein)
          </label>
          <div class="form-group">
            <div class="col-lg-10">
              <select class="form-control" rows="2" id="fernpilotenzeugnis" name="fernpilotenzeugnis" [ngModel]="null" [(ngModel)]="staff.fernpilotenzeugnis" #fernpilotenzeugnis="ngModel">
                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                <option value="A1A3">A1/A3</option>
                <option value="A2">A2</option>
                <option value="STS">STS-Ausbildung</option>
            </select>                        
          </div>
        </div>
        <div class="form-group">
          <label for="foto">
            Fotograf:in
          </label>
          <div class="form-group">
            <div class="col-lg-10">
              <select class="form-control" rows="2" id="foto" name="foto" [ngModel]="null" [(ngModel)]="staff.foto" #foto="ngModel" required>
                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                <option value="1">Ja</option>
                <option value="0">Nein</option>
            </select>                        
          </div>
        </div>
  </div>
  <div class="form-group">
    <label for="video">
      Videograf:in
    </label>
    <div class="form-group">
      <div class="col-lg-10">
        <select class="form-control" rows="5" id="video" name="video" [ngModel]="null" [(ngModel)]="staff.video" #video="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="1">Ja</option>
          <option value="0">Nein</option>
      </select>                        
    </div>
  </div>
</div>
<div class="form-group">
  <label for="sonstigeKompetenzen">
    Weitere Kompetenzen (bspw. Hilfe nur beim Stufenprogramm)
  </label>
  <div class="form-group">
    <div class="col-lg-10">
      <textarea class="form-control" rows="5" id="sonstigeKompetenzen" name="sonstigeKompetenzen" placeholder="Hier ist Platz für weiter Kompetenzen, die oben nicht aufgeführt sind." [(ngModel)]="staff.sonstigeKompetenzen" #sonstigeKompetenzen="ngModel"></textarea>
    </div>
</div>
</div>
        
  </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">Präferenzen für den Hilfebereich</div>
          <div class="card-body">
            <div class="alert alert-light">
              Dies ist ausschließlich eine Präferenz - es gibt keine Garantie für die ausgewählten Aufgabenbereiche!
            </div>
            <div class="form-group">
              <label for="kochen_essen" class="col-lg-10 control-label">Kochen / Essensausgabe (Samstag)</label>
              <div class="col-lg-10">
                  <select class="form-control" rows="2" id="kochen_essen" name="kochen_essen" [ngModel]="null" [(ngModel)]="staff.kochen_essen" #kochen_essen="ngModel" required>
                      <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                      <option value="zustimmung">Zustimmung</option>
                      <option value="neutral">Neutral</option>
                      <option value="ablehnung">Ablehnung</option>
                  </select>
              </div>
          </div>
          <div class="form-group">
            <label for="zeltauf_abbau" class="col-lg-10 control-label">Zelte auf- / abbauen (Donnerstag, Freitag, Sonntag)</label>
            <div class="col-lg-10">
                <select class="form-control" rows="2" id="zeltauf_abbau" name="zeltauf_abbau" [ngModel]="null" [(ngModel)]="staff.zeltauf_abbau" #zeltauf_abbau="ngModel" required>
                    <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                    <option value="zustimmung">Zustimmung</option>
                    <option value="neutral">Neutral</option>
                    <option value="ablehnung">Ablehnung</option>
                </select>
            </div>
        </div>
        <div class="form-group">
          <label for="workshop" class="col-lg-10 control-label">Workshop betreuuen (Samstag)</label>
          <div class="col-lg-10">
              <select class="form-control" rows="2" id="workshop" name="workshop" [ngModel]="null" [(ngModel)]="staff.workshop" #workshop="ngModel" required>
                  <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                  <option value="zustimmung">Zustimmung</option>
                  <option value="neutral">Neutral</option>
                  <option value="ablehnung">Ablehnung</option>
              </select>
          </div>
      </div>
      <div class="form-group">
        <label for="buehnenauf_abbau" class="col-lg-10 control-label">Unterstützung beim Auf- / Abbau der Bühne (Freitag, Samstagnacht, Sonntag)</label>
        <div class="col-lg-10">
            <select class="form-control" rows="2" id="buehnenauf_abbau" name="buehnenauf_abbau" [ngModel]="null" [(ngModel)]="staff.buehnenauf_abbau" #buehnenauf_abbau="ngModel" required>
                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                <option value="zustimmung">Zustimmung</option>
                <option value="neutral">Neutral</option>
                <option value="ablehnung">Ablehnung</option>
            </select>
        </div>
    </div>
    <div class="form-group">
      <label for="fotografieren" class="col-lg-10 control-label">Fotografieren (Freitag, Samstag, Sonntag)</label>
      <div class="col-lg-10">
          <select class="form-control" rows="2" id="fotografieren" name="fotografieren" [ngModel]="null" [(ngModel)]="staff.fotografieren" #fotografieren="ngModel" required>
              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
              <option value="zustimmung">Zustimmung</option>
              <option value="neutral">Neutral</option>
              <option value="ablehnung">Ablehnung</option>
          </select>
      </div>
  </div>
  <div class="form-group">
    <label for="filmische_doku" class="col-lg-10 control-label">Filmische Dokumentation (Freitag, Samstag, Sonntag)</label>
    <div class="col-lg-10">
        <select class="form-control" rows="2" id="filmische_doku" name="filmische_doku" [ngModel]="null" [(ngModel)]="staff.filmische_doku" #filmische_doku="ngModel" required>
            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
            <option value="zustimmung">Zustimmung</option>
            <option value="neutral">Neutral</option>
            <option value="ablehnung">Ablehnung</option>
        </select>
    </div>
</div>
<div class="form-group">
  <label for="videoschnitt" class="col-lg-10 control-label">Videoschnitt (Tage bis Wochen nach dem Ruhrjamb)</label>
  <div class="col-lg-10">
      <select class="form-control" rows="2" id="videoschnitt" name="videoschnitt" [ngModel]="null" [(ngModel)]="staff.videoschnitt" #videoschnitt="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="zustimmung">Zustimmung</option>
          <option value="neutral">Neutral</option>
          <option value="ablehnung">Ablehnung</option>
      </select>
  </div>
</div>
<div class="form-group">
  <label for="springer_in" class="col-lg-10 control-label">Springer:in (Samstag)</label>
  <div class="col-lg-10">
      <select class="form-control" rows="2" id="springer_in" name="springer_in" [ngModel]="null" [(ngModel)]="staff.springer_in" #springer_in="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="zustimmung">Zustimmung</option>
          <option value="neutral">Neutral</option>
          <option value="ablehnung">Ablehnung</option>
      </select>
  </div>
</div>
<div class="form-group">
  <label for="toilettendienst" class="col-lg-10 control-label">Toilettendienst (Samstag)</label>
  <div class="col-lg-10">
      <select class="form-control" rows="2" id="toilettendienst" name="toilettendienst" [ngModel]="null" [(ngModel)]="staff.toilettendienst" #toilettendienst="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="zustimmung">Zustimmung</option>
          <option value="neutral">Neutral</option>
          <option value="ablehnung">Ablehnung</option>
      </select>
  </div>
</div>
<div class="form-group">
  <label for="maskottchen" class="col-lg-10 control-label">Maskottchen (Samstag)</label>
  <div class="col-lg-10">
      <select class="form-control" rows="2" id="maskottchen" name="maskottchen" [ngModel]="null" [(ngModel)]="staff.maskottchen" #maskottchen="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="zustimmung">Zustimmung</option>
          <option value="neutral">Neutral</option>
          <option value="ablehnung">Ablehnung</option>
      </select>
  </div>
</div>
<div class="form-group">
  <label for="ordner_in" class="col-lg-10 control-label">Ordner:in (Samstag)</label>
  <div class="col-lg-10">
      <select class="form-control" rows="2" id="ordner_in" name="ordner_in" [ngModel]="null" [(ngModel)]="staff.ordner_in" #ordner_in="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="zustimmung">Zustimmung</option>
          <option value="neutral">Neutral</option>
          <option value="ablehnung">Ablehnung</option>
      </select>
  </div>
</div>
<div class="form-group">
  <label for="nachtwache" class="col-lg-10 control-label">Nachtwache (Donnerstag - Sonntag)</label>
  <div class="col-lg-10">
      <select class="form-control" rows="2" id="nachtwache" name="nachtwache" [ngModel]="null" [(ngModel)]="staff.nachtwache" #nachtwache="ngModel" required>
          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
          <option value="zustimmung">Zustimmung</option>
          <option value="neutral">Neutral</option>
          <option value="ablehnung">Ablehnung</option>
      </select>
  </div>
</div>
      </div>
      </div>
      <div class="card">
        <div class="card-header">Fotos und Videos</div>
        <div class="card-body">
          <div class="alert alert-secondary">
            Während der Veranstaltung werden hauptsächlich zu Dokumentationszwecken Fotografien erstellt und Filme gedreht. Wir behalten uns vor, die Fotos und Filme zu veröffentlichen (z. B. auf den Social-Media-Kanälen, Veranstaltungen oder auf unserer Homepage).
          </div>

          <div class="form-group">
            <label for="foto_video_agreement" class="col-lg-12 control-label"></label>
            <div class="col-lg-12 row">
              <div class="col-1">
                <input type="checkbox" id="foto_video_agreement" name="foto_video_agreement" [ngModel]=null [(ngModel)]="staff.foto_video_agreement" #foto_video_agreement="ngModel" [value]="foto_video_agreement">
              </div>
              <div class="col-11">
                Hiermit erkläre ich mich einverstanden, dass Bild- und Tonaufnahmen im Rahmen des 8. Ruhrjambs erstellt werden dürfen, auf denen ich zu sehen bin. Diese Bild- und Tonaufnahmen dürfen vom Diözesanverband der Deutschen Pfadfinderschaft Sankt Georg (DPSG) Essen und seinen Beauftragten zeitlich und räumlich unbefristet auf folgenden Plattformen (auf den sozialen Netzwerken wie z. B. Facebook, WhatsApp, Instagram, Homepage DPSG Essen, Vereinszeitschrift, Flyer, Plakate für zukünftige Veranstaltungen, Pressemitteilungen des Trägers) verwendet werden.
              </div>
  
              <div *ngIf="foto_video_agreement.errors?.required && foto_video_agreement.dirty" class="alert alert-danger col-lg-12">
                Einverständniserklärung Fotos und Videos ist ein Pflichtfeld
              </div>
            </div>
          </div>
      </div>
      </div>
      
        <div class="container">

        <div class="form-group" *ngIf="subscription_id == undefined">
          <label for="check" class="col-lg-12 control-label"></label>
          <div class="col-lg-12 row">
            <div class="col-1">
              <input type="checkbox" id="check" name="check" [ngModel]=null [(ngModel)]="staff.check" #check="ngModel" [value]="check" required>
            </div>
            <div class="col-11">
              Hiermit bestätige ich meine Anmeldung am Ruhrjamb des DPSG Diözesanverbands Essen. Die in dieser Anmeldung angegebenen personenbezogenen Daten, die allein zum Zwecke der Durchführung des entstehenden Vertragsverhältnisses notwendig und erforderlich sind, werden auf Grundlage gesetzlicher Berechtigungen erhoben. Ich bestätige zudem, dass eine DPSG-Mitgliedschaft vorliegt.<br />
            </div>

            <div *ngIf="check.errors?.required && check.dirty" class="alert alert-danger col-lg-12">
              Einverständniserklärung ist ein Pflichtfeld
            </div>
          </div>
        </div>
        </div>
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/']" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="addStaffForm.invalid">Anlegen</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>