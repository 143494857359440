import {Routes, RouterModule} from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminEventCreateComponent } from './events/admin-event-create/admin-event-create.component';
import { AdminEventDeleteComponent } from './events/admin-event-delete/admin-event-delete.component';
import { AdminEventEditComponent } from './events/admin-event-edit/admin-event-edit.component';
import { AdminEventListComponent } from './events/admin-event-list/admin-event-list.component';
import { AdminEventRegistrationCreateComponent } from './events/registrations/admin-event-registration-create/admin-event-registration-create.component';
import { AdminEventRegistrationDeleteComponent } from './events/registrations/admin-event-registration-delete/admin-event-registration-delete.component';
import { AdminEventRegistrationEditComponent } from './events/registrations/admin-event-registration-edit/admin-event-registration-edit.component';
import { AdminEventRegistrationListComponent } from './events/registrations/admin-event-registration-list/admin-event-registration-list.component';
import { AdminEventRegistrationSubscriptionsListComponent } from './events/registrations/subscriptions/admin-event-registration-subscriptions-list/admin-event-registration-subscriptions-list.component';
import { AdminUserCreateComponent } from './users/admin-user-create/admin-user-create.component';
import { AdminUserEditComponent } from './users/admin-user-edit/admin-user-edit.component';
import { AdminUserDeleteComponent } from './users/admin-user-delete/admin-user-delete.component';
import { AdminUserListComponent } from './users/admin-user-list/admin-user-list.component';
import { AdminUserResetComponent } from './users/admin-user-reset/admin-user-reset.component';
import { AdminTemplateCopyComponent } from './templates/admin-template-copy/admin-template-copy.component';
import { AdminTemplatesCreateComponent } from './templates/admin-templates-create/admin-templates-create.component';
import { AdminTemplatesDeleteComponent } from './templates/admin-templates-delete/admin-templates-delete.component';
import { AdminTemplatesEditComponent } from './templates/admin-templates-edit/admin-templates-edit.component';
import { AdminTemplatesListComponent } from './templates/admin-templates-list/admin-templates-list.component';
import { AdminTemplatePagesCreateComponent } from './templates/pages/admin-template-pages-create/admin-template-pages-create.component';
import { AdminTemplatePagesDeleteComponent } from './templates/pages/admin-template-pages-delete/admin-template-pages-delete.component';
import { AdminTemplatePagesEditComponent } from './templates/pages/admin-template-pages-edit/admin-template-pages-edit.component';
import { AdminTemplateFieldsCreateComponent } from './templates/pages/fields/admin-template-fields-create/admin-template-fields-create.component';
import { AdminTemplateFieldsDeleteComponent } from './templates/pages/fields/admin-template-fields-delete/admin-template-fields-delete.component';
import { AdminTemplateFieldsEditComponent } from './templates/pages/fields/admin-template-fields-edit/admin-template-fields-edit.component';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { AdminTemplateDashboardComponent } from './templates/admin-template-dashboard/admin-template-dashboard.component';
import { PfadiDu2022ListComponent } from './custom/pfadi-du2022-list/pfadi-du2022-list.component';
import { JuffiDu2023ListComponent } from './custom/juffi-du2023-list/juffi-du2023-list.component';
import { RoverDu2023ListComponent } from './custom/rover-du2023-list/rover-du2023-list.component';
import { AdminEventRolesComponent } from './events/admin-event-roles/admin-event-roles.component';
import { AdminEventRegistrationRolesComponent } from './events/registrations/admin-event-registration-roles/admin-event-registration-roles.component';
import { Ruhrjamb2024ListComponent } from './custom/ruhrjamb2024-list/ruhrjamb2024-list.component';


export const adminRoutes: Routes = [
  { path: '', component: AdminDashboardComponent},
  { path: 'event/create', component: AdminEventCreateComponent},
  { path: 'event/delete/:id', component: AdminEventDeleteComponent},
  { path: 'event/edit/:id', component: AdminEventEditComponent},
  { path: 'event/roles/:id', component: AdminEventRolesComponent},
  { path: 'events', component: AdminEventListComponent},
  { path: 'event/:event_id/registration/create', component: AdminEventRegistrationCreateComponent},
  { path: 'event/:event_id/registration/delete/:id', component: AdminEventRegistrationDeleteComponent},
  { path: 'event/:event_id/registration/edit/:id', component: AdminEventRegistrationEditComponent},
  { path: 'event/:event_id/registration/roles/:id', component: AdminEventRegistrationRolesComponent},
  { path: 'event/:event_id/registrations', component: AdminEventRegistrationListComponent},
  { path: 'event/:event_id/registration/:registration_id/subscriptions', component: AdminEventRegistrationSubscriptionsListComponent},
  { path: 'event/custom/pfadi_du_2022/registrations', component: PfadiDu2022ListComponent},
  { path: 'event/custom/juffi_du_2023/registrations', component: JuffiDu2023ListComponent},
  { path: 'event/custom/rover_du_2023/registrations', component: RoverDu2023ListComponent},
  { path: 'event/custom/ruhrjamb2024/registrations', component: Ruhrjamb2024ListComponent},
  { path: 'user/create', component: AdminUserCreateComponent},
  { path: 'user/delete/:id', component: AdminUserDeleteComponent},
  { path: 'user/edit/:id', component: AdminUserEditComponent},
  { path: 'user/reset/:id', component: AdminUserResetComponent},
  { path: 'users', component: AdminUserListComponent},
  { path: 'template/create', component: AdminTemplatesCreateComponent},
  { path: 'template/delete/:id', component: AdminTemplatesDeleteComponent},
  { path: 'template/edit/:id', component: AdminTemplatesEditComponent},
  { path: 'templates', component: AdminTemplatesListComponent},
  { path: 'template/:id', component: AdminTemplateDashboardComponent},
  { path: 'template/:id/copy', component: AdminTemplateCopyComponent},
  { path: 'template/:template_id/page/create', component: AdminTemplatePagesCreateComponent},
  { path: 'template/:template_id/page/delete/:id', component: AdminTemplatePagesDeleteComponent},
  { path: 'template/:template_id/page/edit/:id', component: AdminTemplatePagesEditComponent},
  { path: 'template/:template_id/page/:page_id/field/create', component: AdminTemplateFieldsCreateComponent},
  { path: 'template/:template_id/page/:page_id/field/delete/:id', component: AdminTemplateFieldsDeleteComponent},
  { path: 'template/:template_id/page/:page_id/field/edit/:id', component: AdminTemplateFieldsEditComponent},
];
