<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">{{registration.title}}</h1>
    <h2 class="display-6">{{event.title}}</h2>
  </div>
  <img src="{{backendurl}}images/{{event.imageLink}}" alt="">
</div>

<div class="text-center">
  <div class="btn-group" role="group">
  <button type="button" class="btn btn-light" [class.active]="switch == 'uebersicht'" id="uebersicht" (click)="updateSwitch($event)">Übersicht</button>
  <button type="button" class="btn btn-light" [class.active]="switch == 'gen'" id="gen" (click)="updateSwitch($event)">Generierungen</button>
</div>
</div>
<div class="container">
  <form *ngIf="switch=='uebersicht' || switch=='teilnehmende' || switch=='anmeldende' || switch=='staves'">
    <div class="form-group">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
      </div>
    </div>
  </form>
  <div *ngIf="switch=='uebersicht'">
    Gesamt: <b>{{total}}</b>
     <a [routerLink]="[]" (click)="copyEmail()" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-envelope-o"></i> E-Mail Adressen kopieren</a>
    <a [routerLink]="[]" (click)="export()" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
  <table *ngIf="switch=='uebersicht'" class="table table-bordered table-striped">

    <thead>
      <tr>
        <td></td>
        <td>Nachname</td>
        <td>Vorname</td>
        <td>Stamm</td>
        <td>Bezirk</td>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let subscription of subscriptions | async | filter:{nachname: filter, vorname: filter, bezirk: filter, stamm: filter}">
        <tr>
        <td>
      <!--    <button type="button" class="btn btn-link" *ngIf="subscription.expanded" (click)="subscription.expanded = false">-</button>
          <button type="button" class="btn btn-link" *ngIf="!subscription.expanded" (click)="subscription.expanded = true">+</button>-->
        </td>
        <td>{{ subscription.nachname }}</td>
        <td>{{ subscription.vorname }}</td>
        <td>{{ subscription.stamm }}</td>
        <td>{{ subscription.bezirk }}</td>
      </tr>

      </ng-container>
    </tbody>

  </table>



<div id="generierungen" *ngIf="switch=='gen'">
  <div class="card mx-auto">
    <div class="card-body text-left">
      <a [routerLink]="[]" (click)="rdp()" class="btn btn-default" role="button" aria-expanded="false"><i class="fa fa-file-pdf-o"></i> RDP-Listen generieren</a>
    </div>
  </div>
</div>

</div>
