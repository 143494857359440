import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../services/user.service';
import { Router} from '@angular/router';
import { StammService } from '../../services/stamm.service';
import { Stamm } from '../../models/stamm';
import { BezirkService } from '../../services/bezirk.service';
import { Bezirk } from '../../models/bezirk';
import {Observable} from 'rxjs';
import { NotifyService } from '../../services/notify.service';

@Component({
  selector: 'app-change-data',
  templateUrl: './change-data.component.html',
  styleUrls: ['./change-data.component.css']
})
export class ChangeDataComponent implements OnInit {

  bezirkSelected: any = true;
  check: any;
  user_id: any;
  user_admin: any;
  user: any;
  status: any;
  message: any;
  staemme:  Observable<Stamm[]>;
  bezirke:  Observable<Bezirk[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bezirkService: BezirkService,
    private stammService: StammService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
  ) { }

  ngOnInit() {

    this.userService.isUserCompleted().subscribe((completed: any) => {
    if(completed === false) {
      this.status = 'error';
      this.message = "Bitte vervollständige deine Daten.";
    }
  });

    this.bezirke = this.bezirkService.getBezirke()

   this.user = this.userService.getActualUser().subscribe(
     user => {
       this.user = user;
       this.user.gender = this.user['gender'];
       this.user.pronoun = this.user['pronoun'];
       this.user.geburtsdatum = this.user['geburtsdatum'];
       this.user.surname = this.user['surname'];
       this.user.givenname = this.user['givenname'];
       this.user.email = this.user['email'];
       this.user.bezirk = this.user['bezirk'];
       this.staemme = this.stammService.getStaemme(this.user.bezirk)
       this.user.stamm = this.user['stamm'];
       this.user.strasse = this.user['strasse'];
       this.user.hausnummer = this.user['hausnummer'];
       this.user.plz = this.user['plz'];
       this.user.ort = this.user['ort'];
       this.user.telefonnummer = this.user['telefonnummer'];
       this.user_admin = this.user['admin'];
       this.user_id = this.user['id'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
  }

  getStaemme(bezirks_id: any) {
    this.bezirkSelected = null
    this.staemme = this.stammService.getStaemme(bezirks_id.value)
  }

  changeData(user) {
    user.id = this.user_id;
    user.admin = this.user_admin
    this.userService.updateUser(user)
    .subscribe(
      user => {
        this.notifyService.snotifySuccess(user['message'])
        this.router.navigate(['/']);
      },
      error => {
        console.log(<any>error);
        this.notifyService.snotifyError(error['message'])
      });
    }

}
