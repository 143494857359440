
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BackendService } from './backend.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../classes/settings';

@Injectable()
export class UserService {

  constructor(
    private backendService: BackendService,
    private http: HttpClient
) { }

  getActualUser(): Observable<any>{
        return this.backendService.get('user')
        .map((response) => response)
      }

      getUser(user: string): Observable<any> {
        return this.backendService.get('users/' + user)
           .map((response) => response);
      }

  getUsers(): Observable<any>{
        return this.backendService.get('users')
        .map((response) => response)
      }

      getNonAdminUsers(): Observable<any>{
        return this.backendService.get('nonAdminUsers')
        .map((response) => response)
      }

      isUserCompleted(): Observable<any>{
            return this.backendService.get('user/completed')
            .map((response) => response)
          }

      updateUser(user: Object): Observable<User[]> {
       const apiUrl = 'users';
       const url = `${apiUrl}/${user['id']}`;
       return this.backendService.put(url, user)
          .map((response) => response)
         .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
     }

  addUser(user: Object): Observable<any> {
          return this.http.post(Settings.appURL + Settings.apiPath + 'public/register/', user)
          .map((response) => response)
          .catch((error: any) => observableThrowError(error.error || {message: 'Server Error'} ));
        }

        deleteUser(user: string): Observable<any> {
          const apiUrl = 'users';
          const url = `${apiUrl}/${user}`;
          return this.backendService.delete(url)
             .map((response) => response)
            .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
       }

       resetPassword(data) {
         return this.backendService.post('forgot/password/', data)
           .map((response) => response)
          .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
       }

       changePassword(data): Observable<any> {
        const apiUrl = 'user/password';
        const url = `${apiUrl}`;
        return this.backendService.post(url, data)
           .map((response) => response)
          .catch((error: any) => observableThrowError(error.error || {message: 'Server Error'} ));
      }

}
