import { Component, OnInit } from '@angular/core';
import { Ruhrjamb2024Service } from '../../../../services/ruhrjamb2024.service';
import { NotifyService } from '../../../../services/notify.service';
import { ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-ruhrjamb2024-del-tn',
  templateUrl: './ruhrjamb2024-del-tn.component.html',
  styleUrls: ['./ruhrjamb2024-del-tn.component.css']
})
export class Ruhrjamb2024DelTNComponent implements OnInit {
  event: any;
  params: any;
  subscription_id: any;

  constructor(
    private pfadidu2022Service: Ruhrjamb2024Service,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotifyService
  ) { }


  deleteTN() {
    this.pfadidu2022Service.deleteTN(this.subscription_id).subscribe(
      data => {
        this.notifyService.snotifySuccess("Anmeldung erfolgreich gelöscht!")
        this.router.navigate(['/events/custom/ruhrjamb2024/registrations']);
      },
      error => {
        this.notifyService.snotifyError("Beim Löschen der Anmeldung ist ein Fehler aufgetreten!")
        console.log(<any>error);
      });
    }

  ngOnInit(): void {
    this.event = new Array()
    this.event.title = "Ruhrjamb 2024"
    this.event.imageLink = "assets/images/ruhrjamb2024.png";
    this.event.active = 0;

    this.params = this.activatedRoute.params.subscribe(params => this.subscription_id = params['tn_id']);
  }

}
