
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Template } from '../models/template';
import { BackendService } from '../services/backend.service';


@Injectable()
export class TemplateService {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addTemplate(template: Object): Observable<Template[]> {
    return this.backendService.post('templates/', template)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
    }

  getTemplates(): Observable<any>{
    return this.backendService.get('templates/')
       .map((response) => response);
  }

  getTemplate(template: string): Observable<any> {
    return this.backendService.get('templates/' + template)
       .map((response) => response);
  }

  updateTemplate(template: Object): Observable<Template[]> {
   const apiUrl = 'templates';
   const url = `${apiUrl}/${template['id']}`;
   return this.backendService.put(url, template)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
 }

 deleteTemplate(template: string): Observable<any> {
   const apiUrl = 'templates';
   const url = `${apiUrl}/${template}`;
   return this.backendService.delete(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
}

  isUsed(template: string): Observable<any> {
    return this.backendService.get('templates/' + template + '/isUsed')
    .map((response) => response);
  }

  copyTemplate(template: string): Observable<any> {
    return this.backendService.get('templates/' + template + '/copy')
    .map((response) => response);
  }

}
