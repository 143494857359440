import { Component, OnInit, Input, Pipe} from '@angular/core';
import { Event } from '../../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../services/registration.service';
import { Registration } from '../../../models/registration';
import { EventService } from '../../../services/event.service';
import { BezirkService } from '../../../services/bezirk.service';
import { StammService } from '../../../services/stamm.service';
import { TemplatePageService } from '../../../services/template-page.service';
import { UserService } from '../../../services/user.service';
import {Observable} from 'rxjs';
import { User } from '../../../models/user';
import { Router} from '@angular/router';
import { FilterPipe } from '../../../pipes/filter.pipe';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { ExportService } from '../../../services/export.service';
import { JuffiDu2023Service } from '../../../services/juffi-du-2023.service';

@Component({
  selector: 'app-juffi-du2023-list',
  templateUrl: './juffi-du2023-list.component.html',
  styleUrls: ['./juffi-du2023-list.component.css']
})

export class JuffiDu2023ListComponent implements OnInit {

  event_id: any;
  registration_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  switch: any;
  groups: any;
  tns: any;
  sum: any;
  totalofGroups: any;
  totalofTns: any;
  totalofAnmeldenden: any;
  totalofStaVes: any;
  totalofStaffs: any;
  filter: any;
  anmeldenden: any;
  staves: any;
  staffs: any;
  exportData: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bezirkService: BezirkService,
    private eventService: EventService,
    private exportService: ExportService,
    private registrationService: RegistrationService,
    private stammService: StammService,
    private juffidu2023Service: JuffiDu2023Service,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router
  ) { }

  export(what) {
    switch(what) {
      case "tns": {
        this.tns.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
 
      case "groups": {
        this.groups.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
 
      case "anmeldenden": {
        this.anmeldenden.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
 
      case "staves": {
        this.staves.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
      case "staffs": {
        this.staffs.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
    }
 
  }

 rdp(){
   this.juffidu2023Service.rdp()
       .subscribe((res: any) => {
                   let blob: Blob;
                   let daten: string;

                       blob = new Blob([res], {
                           type: 'apllication/pdf'
                       });

                       const nav = (window.navigator as any)
                       if (nav.msSaveOrOpenBlob) {
                           nav.msSaveOrOpenBlob(blob, "rdp" + '.pdf');
                   } else {
                       const link = document.createElement('a');
                       link.style.display = 'none';
                       document.body.appendChild(link);
                       if (link.download !== undefined) {
                           link.setAttribute('href', URL.createObjectURL(blob));
                           link.setAttribute('download', "rdp" + '.pdf');
                           link.click();
                       }
                       document.body.removeChild(link);
                   }
               });
     }

  updateSwitch(event) {
      this.switch = event.target.id;
      }

  ngOnInit() {
    this.switch = "uebersicht";
    this.sum = 0;
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);

    this.event = new Array()
    this.event.title = "Juffi-Diözesansommerlager"
    this.event.imageLink = "assets/images/juffidu2023.png";
    this.event.active = 1;

    this.groups = this.juffidu2023Service.getGroups()
    this.groups.subscribe(
      result => {
        this.totalofGroups = result.length
      }
    )

    this.tns = this.juffidu2023Service.getTNs()

    this.tns.subscribe(
      result => {
        this.totalofTns = result.length
      }
    )

    this.staves = this.juffidu2023Service.getStaves();
    this.staves.subscribe(
      result => {
        this.totalofStaVes = result.length
      }
    )

    this.staffs = this.juffidu2023Service.getStaffs();
    this.staffs.subscribe(
      result => {
        this.totalofStaffs = result.length
      }
    )

  }

}

