import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../../../services/registration.service';
import { EventService } from '../../../../../services/event.service';
import { BezirkService } from '../../../../../services/bezirk.service';
import { StammService } from '../../../../../services/stamm.service';
import { TemplatePageService } from '../../../../../services/template-page.service';
import { SubscriptionService } from '../../../../../services/subscription.service';
import { UserService } from '../../../../../services/user.service';
import { Router} from '@angular/router';
import {TemplateFieldService} from '../../../../../services/template-field.service';
import { ExportService } from '../../../../../services/export.service';
import { NotifyService } from '../../../../../services/notify.service';
import { Settings } from '../../../../../classes/settings'

@Component({
  selector: 'app-admin-event-registration-subscriptions-list',
  templateUrl: './admin-event-registration-subscriptions-list.component.html',
  styleUrls: ['./admin-event-registration-subscriptions-list.component.css']
})

export class AdminEventRegistrationSubscriptionsListComponent implements OnInit {

  event_id: any;
  registration_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  subscriptions: any;
  templateFields: any;
  templatePage_id: any;
  subscription: any;
  choices: any;
  switch: any;
  total: any;
  filter: any;
  exportData: any;
  backendurl: any;
  tns: any;
  tnanzahl: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private subscriptionService: SubscriptionService,
    private templatePageService: TemplatePageService,
    private templateFieldService: TemplateFieldService,
    private exportService: ExportService,
    private notifyService: NotifyService,
  ) { }

  export() {
        this.subscriptions.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
    }

      rdp(){
        this.subscriptionService.rdp(this.event_id, this.registration_id)
            .subscribe((res: any) => {
                        let blob: Blob;
                        let daten: string;

                            blob = new Blob([res], {
                                type: 'apllication/pdf'
                            });

                        const nav = (window.navigator as any)
                        if (nav.msSaveOrOpenBlob) {
                            nav.msSaveOrOpenBlob(blob, "rdp" + '.pdf');
                        } else {
                            const link = document.createElement('a');
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            if (link.download !== undefined) {
                                link.setAttribute('href', URL.createObjectURL(blob));
                                link.setAttribute('download', "rdp" + '.pdf');
                                link.click();
                            }
                            document.body.removeChild(link);
                        }
                    });
          }
          copyEmail() {
            this.exportService.copyEmail(this.registration_id).subscribe(
              data =>{
                const selBox = document.createElement('textarea');
                selBox.style.position = 'fixed';
                selBox.style.left = '0';
                selBox.style.top = '0';
                selBox.style.opacity = '0';
                selBox.value = data.data;
                document.body.appendChild(selBox);
                selBox.focus();
                selBox.select();
                document.execCommand('copy');
                document.body.removeChild(selBox);
                this.notifyService.snotifySuccess("E-Mail Adressen in die Zwischenablage kopiert!")
              }
            )
          }


            updateSwitch(event) {
                this.switch = event.target.id;
                }



    ngOnInit() {
      this.backendurl = Settings.appURL
      this.switch = "uebersicht";
      this.total = 0;

  this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
  this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);

  this.event = this.eventService.getEvent(this.event_id).subscribe(
    data => {
      this.event.title = data['title'];
      this.event.imageLink = data['imageLink'];
    },
    error => console.log(<any>error)
  );

  this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
    data => {
      this.registration.title = data['title'];
      this.registration.template = data['template'];
      this.registration.description = data['description'];
      this.templatePageService.getTemplatePages(this.registration.template).subscribe(
        pages => {
          pages.forEach(
            page => {
              this.templatePage_id = page.id
              this.templateFieldService.getTemplateFields(page.id).subscribe(
                fields => {
                  fields.forEach(
                    field => {
                      if(field.type == "option" || field.type == "choice" ) {
                        field.choices = JSON.parse(field.choices)
                      }
                    }
                  )
                  this.templateFields=fields
                }
              )

            }
          )
        }
      )    },
    error => console.log(<any>error)
  );

  this.subscriptions = this.subscriptionService.getSubscriptions(this.event_id, this.registration_id)
  this.subscriptions.subscribe(
    result => {
      this.total = result.length
    }
  )

}
}
