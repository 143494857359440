import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Template } from '../../../models/template';
import { TemplateService } from '../../../services/template.service';

@Component({
  selector: 'app-admin-templates-list',
  templateUrl: './admin-templates-list.component.html',
  styleUrls: ['./admin-templates-list.component.css']
})
export class AdminTemplatesListComponent implements OnInit {
  templates: Observable<Template[]>;

  constructor(private templateService: TemplateService) { }

  ngOnInit() {
    this.templates = this.templateService.getTemplates();
  }

  checkIfUsed(templateid) {
    this.templateService.isUsed(templateid).subscribe(
            data => {
              if (data == 1) {
                return true
              }
            }
          )
  }

}
