import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../../services/registration.service';
import { Registration } from '../../../../models/registration';
import { EventService } from '../../../../services/event.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-event-registration-list',
  templateUrl: './admin-event-registration-list.component.html',
  styleUrls: ['./admin-event-registration-list.component.css']
})
export class AdminEventRegistrationListComponent implements OnInit {

  event_id: any;
  params: any;
  registrations:  Observable<Registration[]>;
  event:  any;

   constructor(private activatedRoute: ActivatedRoute, private registrationService: RegistrationService, private eventService: EventService) { }

   ngOnInit() {
     this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
     this.registrations = this.registrationService.getRegistrations(this.event_id);
     this.event = this.eventService.getEvent(this.event_id).subscribe(
       data => {
         this.event.title = data['title'];
         this.event.id = data['id'];
       },
       error => console.log(<any>error));

   }

}
