import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { TemplateService } from '../../../services/template.service';
import { NotifyService } from '../../../services/notify.service';


@Component({
  selector: 'app-admin-template-copy',
  templateUrl: './admin-template-copy.component.html',
  styleUrls: ['./admin-template-copy.component.css']
})
export class AdminTemplateCopyComponent implements OnInit {
  id: any;
  params: any;
  template_id: any;
  template: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notifyService: NotifyService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  
  copyTemplate() {
    this.templateService.copyTemplate(this.template_id).subscribe(
      data => {
        this.notifyService.snotifySuccess("Template erfolgreich kopiert!")
        this.router.navigate(['/admin/template/' + this.template_id]);
      },
      error => {
        this.notifyService.snotifyError("Beim Kopieren des Templates ist ein Fehler aufgetreten!")
        console.log(<any>error);
      });
    }


  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['id']);

      this.template = this.templateService.getTemplate(this.template_id).subscribe(
        data => {
          this.template.title = data['title'];
        },
        error => console.log(<any>error)
      );
    }

      ngOnDestroy() {
        this.params.unsubscribe();
      }
    }
