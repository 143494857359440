import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import {throwError as observableThrowError, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private backendService: BackendService
  ) { }

  addRoleAssignment(data: object): Observable<any>{
    return this.backendService.post('roles/addRoleAssignment', data)
       .map((response) => response)
       .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  deleteRoleAssignment(data: object): Observable<any>{
    return this.backendService.post('roles/deleteRoleAssignment', data)
       .map((response) => response)
       .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  getRoleAssignments(type: string, event: string, registration: string): Observable<any>{
    return this.backendService.get('roles/getRoleAssignments/' + type + '/' + event + '/'+ registration)
       .map((response) => response);
  }

  checkRoleAssignment(data: object): Observable<any>{
    return this.backendService.post('roles/checkRoleAssignment', data)
       .map((response) => response)
       .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

}
