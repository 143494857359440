import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { AdminComponent } from './admin/admin.component';
import { ChangeDataComponent } from './user/change-data/change-data.component';
import { LoginComponent } from './login/login.component';
import { EventsComponent } from './events/events.component';
import { RegisterComponent } from './register/register.component';
import {adminRoutes} from './admin/admin.routes';
import {eventsRoutes} from './events/events.routes';
import { AuthGuardService } from './services/auth.guard.service';
import { NgModule } from '@angular/core';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const appRoutes: Routes = [
  { path: '', redirectTo:'/events', pathMatch: 'full'},
  { path: 'events', component: EventsComponent, children: eventsRoutes},
  { path: 'admin', component: AdminComponent, children: adminRoutes, canActivate: [AuthGuardService]},
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'user/changeData', component: ChangeDataComponent, canActivate: [AuthGuardService]},
  { path: 'user/changePassword', component: ChangePasswordComponent, canActivate: [AuthGuardService]},
  { path: 'forgotPassword', component: ForgotPasswordComponent},
  { path: 'resetPassword/:token', component: ResetPasswordComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
