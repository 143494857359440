import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import { EventService } from '../../../../services/event.service';
import { RegistrationService } from '../../../../services/registration.service';
import { UserService } from '../../../../services/user.service';
import { RoleService } from '../../../../services/role.service';

@Component({
  selector: 'app-admin-event-registration-roles',
  templateUrl: './admin-event-registration-roles.component.html',
  styleUrls: ['./admin-event-registration-roles.component.css']
})
export class AdminEventRegistrationRolesComponent implements OnInit {

  event_id: any;
  registration_id: any;
  params: any;
  event:  any;
  registration: any;
  users: any;
  role_assignments: any;

  config = {
    displayFn:(item: any) => { return item.surname + " " + item.givenname + " (" + item.email + ")"; } ,//to support flexible text displaying for each item
    displayKey:"id", //if objects array passed which key to be displayed defaults to description
    search: true ,//true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Benutzer', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo:10, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'mehr' ,// text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Kein Benutzer gefunden!' ,// text to be displayed when no items are found while searching
    searchPlaceholder:'Suchen' ,// label thats displayed in search input,
    clearOnSelection: false ,// clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }

  constructor(
    private activatedRoute: ActivatedRoute, 
    private eventService: EventService,
    private registrationService: RegistrationService,
    private userService: UserService,
    private roleService: RoleService
    ) { }

    
  assignRole(data) {
    let roleAssignment = {
      type: "r",
      event: this.event.id,
      registration: this.registration.id,
      user: data.user.id
    }
    this.roleService.addRoleAssignment(roleAssignment).subscribe(
      data=>{
        console.log(data)
      }
    )
  }

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['id']);

    this.event = this.eventService.getEvent(this.event_id).subscribe(
      data => {
        this.event.title = data['title'];
        this.event.id = data['id'];
      },
      error => console.log(<any>error));

      this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
        data => {
          this.registration.title = data['title'];
          this.registration.id = data['id'];

          this.role_assignments=this.roleService.getRoleAssignments("r", this.event.id, this.registration.id)
        },
        error => console.log(<any>error));

       this.userService.getNonAdminUsers().subscribe(
          data => {
            this.users = data
          }
        )

  }

}