import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../services/user.service';
import { Router} from '@angular/router';
import { StammService } from '../../../services/stamm.service';
import { Stamm } from '../../../models/stamm';
import { BezirkService } from '../../../services/bezirk.service';
import { Bezirk } from '../../../models/bezirk';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.css']
})
export class AdminUserEditComponent implements OnInit {

  bezirkSelected: any = true;
  params: any;
  user_id: any;
  user: any;
  status: any;
  message: any;
  staemme:  Observable<Stamm[]>;
  bezirke:  Observable<Bezirk[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bezirkService: BezirkService,
    private stammService: StammService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.bezirke = this.bezirkService.getBezirke()

    this.params = this.activatedRoute.params.subscribe(params => this.user_id = params['id']);
   this.user = this.userService.getUser(this.user_id).subscribe(
     data => {
       this.user.gender = data['gender'];
       this.user.pronoun = data['pronoun'];
       this.user.geburtsdatum = data['geburtsdatum'];
       this.user.surname = data['surname'];
       this.user.givenname = data['givenname'];
       this.user.email = data['email'];
       this.user.bezirk = data['bezirkid'];
       this.staemme = this.stammService.getStaemme(this.user.bezirk)
       this.user.stamm = data['stammid'];
       this.user.strasse = data['strasse'];
       this.user.hausnummer = data['hausnummer'];
       this.user.plz = data['plz'];
       this.user.ort = data['ort'];
       this.user.telefonnummer = data['telefonnummer'];
       this.user.admin = data['admin'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 getStaemme(bezirks_id: any) {
   this.bezirkSelected = null
   this.staemme = this.stammService.getStaemme(bezirks_id.value)
 }

 updateUser(user) {
   user.id = this.user_id;
   this.userService.updateUser(user)
   .subscribe(
     user => {
       this.status = 'success';
       this.message = user['message'];
       this.router.navigate(['/admin/users']);

     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
