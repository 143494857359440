<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">ANMELDUNG</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <img src="{{event.imageLink}}" alt="">
  </div>
  
  <div class="text-center">
    <div class="btn-group" role="group">
    <button type="button" class="btn btn-light" [class.active]="switch == 'uebersicht'" id="uebersicht" (click)="updateSwitch($event)">Übersicht</button>
    <button type="button" class="btn btn-light" [class.active]="switch == 'teilnehmende'" id="teilnehmende" (click)="updateSwitch($event)">Teilnehmende</button>
    <button type="button" class="btn btn-light" [class.active]="switch == 'staves'" id="staves" (click)="updateSwitch($event)">Stammesverantwortliche</button>
    <button type="button" class="btn btn-light" [class.active]="switch == 'staffs'" id="staffs" (click)="updateSwitch($event)">Staffs</button>
    <!--<button type="button" class="btn btn-light" [class.active]="switch == 'gen'" id="gen" (click)="updateSwitch($event)">Generierungen</button>-->
  </div>
  </div>
  <div class="container">
    <form *ngIf="switch=='uebersicht' || switch=='teilnehmende' || switch=='anmeldende' || switch=='staves'">
      <div class="form-group">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </form>
    <div *ngIf="switch=='uebersicht'">
      Gesamt Gruppen: <b>{{totalofGroups}}</b>
      <a [routerLink]="[]" (click)="export('groups')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
      </div>
    <table *ngIf="switch=='uebersicht'" class="table table-bordered table-striped">
  
      <thead>
        <tr>
          <td>Benutzer</td>
          <td>Stamm</td>
          <td>Bezirk</td>
          <td>Anzahl TN</td>
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let group of groups | async | filter:{benutzer: filter, bezirk: filter, stamm: filter}">
          <td>{{ group.benutzer }}</td>
          <td>{{ group.stamm }}</td>
          <td>{{ group.bezirk }}</td>
          <td>{{ group.anzahl }}</td>
        </tr>
      </tbody>
  
    </table>
  
  <div *ngIf="switch=='teilnehmende'">
    Gesamt Teilnehmende: <b>{{totalofTns}}</b>
    <a [routerLink]="[]" (click)="export('tns')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
  
  
    <table *ngIf="switch=='teilnehmende'" class="table table-bordered table-striped">
  
      <thead>
        <tr>
          <td>Nachname</td>
          <td>Vorname</td>
          <td>Stamm</td>
          <td>Bezirk</td>
          <td>Teilnehmende oder Leitung</td>
        </tr>
      </thead>
  
      <tbody>
        <ng-container *ngFor="let tn of tns | async | filter:{nachname: filter, vorname: filter, bezirk: filter, stamm: filter, tn_leitung: filter, stufe: filter}">
          <tr>
            <td>{{ tn.nachname }}</td>
            <td>{{ tn.vorname }}</td>
            <td>{{ tn.stamm }}</td>
            <td>{{ tn.bezirk }}</td>
            <td>{{ tn.tn_leitung }}</td>
          </tr>
            </ng-container>
      </tbody>
    </table>
    <div *ngIf="switch=='staves'">
      Gesamt Stammesverantwortliche: <b>{{totalofStaVes}}</b>
      <a [routerLink]="[]" (click)="export('staves')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
    <table *ngIf="switch=='staves'" class="table table-bordered table-striped">
      <thead>
        <tr>
          <td>Nachname</td>
          <td>Vorname</td>
          <td>Stamm</td>
          <td>Bezirk</td>
        </tr>
      </thead>
  
      <tbody>
        <ng-container *ngFor="let stave of staves | async | filter:{nachname: filter, vorname: filter, bezirk: filter, stamm: filter}">
          <tr>
          <td>{{ stave.nachname }}</td>
          <td>{{ stave.vorname }}</td>
          <td>{{ stave.stamm }}</td>
          <td>{{ stave.bezirk }}</td>
          </tr>
            </ng-container>
      </tbody>
    </table>
    <div *ngIf="switch=='staffs'">
      Gesamt Staffs: <b>{{totalofStaffs}}</b>
      <a [routerLink]="[]" (click)="export('staffs')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
      </div>
    
    
      <table *ngIf="switch=='staffs'" class="table table-bordered table-striped">
    
        <thead>
          <tr>
            <td>Nachname</td>
            <td>Vorname</td>
            <td>Stamm</td>
            <td>Bezirk</td>
          </tr>
        </thead>
    
        <tbody>
          <ng-container *ngFor="let staff of staffs | async | filter:{nachname: filter, vorname: filter, bezirk: filter, stamm: filter, stufe: filter}">
            <tr>
              <td>{{ staff.nachname }}</td>
              <td>{{ staff.vorname }}</td>
              <td>{{ staff.stamm }}</td>
              <td>{{ staff.bezirk }}</td>
            </tr>
              </ng-container>
        </tbody>
      </table>
    <div id="generierungen" *ngIf="switch=='gen'">
        <div class="card mx-auto">
          <div class="card-body text-left">
            <a [routerLink]="[]" (click)="rdp()" class="btn btn-default" role="button" aria-expanded="false"><i class="fa fa-file-pdf-o"></i> RDP-Listen generieren</a>
          </div>
        </div>
    </div>
      
      