
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegistrationType } from '../models/registrationType';
import { BackendService } from '../services/backend.service';
import { Settings } from '../classes/settings';

@Injectable()
export class RegistrationTypeService {
bums: any;
  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  getRegistrationTypes(): Observable<RegistrationType[]>{
    return this.backendService.get('registration_types')
       .map((response) => response);
  }

  updateRegistrationType(registrationType: Object): Observable<RegistrationType[]> {
   const apiUrl = 'registration_types';
   const url = `${apiUrl}/${registrationType['id']}`;
   return this.backendService.put(url, registrationType)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }
}
