<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">Neue:n Teilnehmende:n anlegen</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <img src="{{event.imageLink}}" alt="">
  </div>
  <div class="col-md-10 col-md-offset-1">
    <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
    <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
    <div class="well well bs-component">
      <form novalidate class="form-horizontal" (ngSubmit)="addTN(addTNForm.value)" #addTNForm="ngForm">
        <fieldset>
        <div class="card">
            <div class="card-header">Persönliche Daten</div>
            <div class="card-body">
                <div class="form-group">
                    <label for="tn_leitung" class="col-lg-10 control-label">Teilnehmende oder Leitung</label>
                    <div class="col-lg-10">
                    <select class="form-control" rows="2" id="tn_leitung" name="tn_leitung" [ngModel]=null ngModel #tn_leitung="ngModel" required>
                        <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
                        <option value="tn">Teilnehmende</option>
                        <option value="leitung">Leitung</option>
                    </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="nachname" class="col-lg-10 control-label">Nachname</label>
                    <div class="col-lg-10">
                    <input type="text" class="form-control" id="nachname" name="nachname" placeholder="Nachname" ngModel #nachname="ngModel" required>
                    <div *ngIf="nachname.errors?.required && nachname.dirty" class="alert alert-danger">
                        Nachname ist ein Pflichtfeld
                    </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="vorname" class="col-lg-10 control-label">Vorname</label>
                    <div class="col-lg-10">
                    <input type="text" class="form-control" id="vorname" name="vorname" placeholder="Vorname" ngModel #vorname="ngModel" required>
                    <div *ngIf="vorname.errors?.required && vorname.dirty" class="alert alert-danger">
                        Vorname ist ein Pflichtfeld
                    </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="geburtsdatum" class="col-lg-10 control-label">Geburtsdatum</label>
                    <div class="col-lg-10">
                    <input type="date" class="form-control" id="geburtsdatum" name="geburtsdatum" placeholder="Geburtsdatum" ngModel #geburtsdatum="ngModel" required>
                    <div *ngIf="geburtsdatum.errors?.required && geburtsdatum.dirty" class="alert alert-danger">
                        Geburtsdatum ist ein Pflichtfeld
                    </div>
                    </div>
                </div>
                <div class="form-group">
                  <label for="stufe_gruppe" class="col-lg-10 control-label">Stufe / Gruppe</label>
                  <div class="col-lg-10">
                  <select class="form-control" rows="2" id="stufe_gruppe" name="stufe_gruppe" [ngModel]=null ngModel #stufe_gruppe="ngModel" required>
                      <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
                      <option value="biber">Biber</option>
                      <option value="woelflinge">Wölflinge</option>
                      <option value="jungpfadfinder">Jungpfadfinder</option>
                      <option value="pfadfinder">Pfadfinder</option>
                      <option value="rover">Rover</option>
                  </select>
                  </div>
                </div>
            </div>
          </div>
          
          <div class="card">
            <div class="card-header">Lebensmittelabfragen</div>
            <div class="card-body">
              <div class="alert alert-secondary">
                Die Verpflegung während des Ruhrjambs ist tierfleischfrei - sollte eine vegane Verpflegung gewünscht sein, gebt diese bitte beim Verpflegungswunsch an!
              </div>
              <div class="form-group">
                <label for="lebensmVerpf" class="col-lg-3 control-label">Verpflegungswunsch</label>
                <div class="col-lg-10 row" *ngFor="let lebensmVerpfOption of lebensmVerpfOptions">
                  <div class="col-lg-1">
                    <input type="checkbox" id="lebensmVerpfOptions" name="lebensmVerpf" value="{{lebensmVerpfOption.name}}" (change)="updateCheckedlebensmVerp(lebensmVerpfOption.name, $event)">
                    </div>
                    <div class="col-lg-11">
                    {{lebensmVerpfOption.name}}
                    </div>
              </div>
              </div>
              <div class="form-group">
                <label for="lebensmUnvert" class="col-lg-3 control-label">Unverträglichkeiten</label>
                  <div class="col-lg-10 row" *ngFor="let lebensmUnvertOption of lebensmUnvertOptions">
                    <div class="col-lg-1">
                      <input type="checkbox" id="lebensmUnvertOptions" name="lebensmUnvert" value="{{lebensmUnvertOption.name}}" (change)="updateCheckedlebensmUnvert(lebensmUnvertOption.name, $event)">
                      </div>
                      <div class="col-lg-11">
                      {{lebensmUnvertOption.name}}
                      </div>
                </div>
              </div>
              <div class="form-group">
                <label for="lebensmSonsUnvert" class="col-lg-12 control-label">Sonstige Unverträglichkeiten</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="lebensmSonsUnvert" name="lebensmSonsUnvert" placeholder="Sonstige Unverträglichkeiten" ngModel #lebensmSonsUnvert="ngModel">
                  <div *ngIf="lebensmSonsUnvert.errors?.required && lebensmSonsUnvert.dirty" class="alert alert-danger">
                    Sonstige Unverträglichkeiten ist ein Pflichtfeld
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">Fotos und Videos</div>
            <div class="card-body">
              <div class="alert alert-secondary">
                Während der Veranstaltung werden hauptsächlich zu Dokumentationszwecken Fotografien erstellt und Filme gedreht. Wir behalten uns vor, die Fotos und Filme zu veröffentlichen (z. B. auf den Social-Media-Kanälen, Veranstaltungen oder auf unserer Homepage).
              </div>
    
              <div class="form-group">
                <label for="foto_video_agreement" class="col-lg-12 control-label"></label>
                <div class="col-lg-12 row">
                  <div class="col-1">
                    <input type="checkbox" id="foto_video_agreement" name="foto_video_agreement" ngModel #foto_video_agreement="ngModel">
                  </div>
                  <div class="col-11">
                    Es liegt eine Einverständniserklärung vor, dass Bild- und Tonaufnahmen im Rahmen des 8. Ruhrjambs erstellt werden dürfen, auf denen die Person zu sehen ist. Diese Bild- und Tonaufnahmen dürfen vom Diözesanverband der Deutschen Pfadfinderschaft Sankt Georg (DPSG) Essen und seinen Beauftragten zeitlich und räumlich unbefristet auf folgenden Plattformen (auf den sozialen Netzwerken wie z. B. Facebook, WhatsApp, Instagram, Homepage DPSG Essen, Vereinszeitschrift, Flyer, Plakate für zukünftige Veranstaltungen, Pressemitteilungen des Trägers) verwendet werden.
                  </div>
                </div>
              </div>
          </div>
          </div>

          <div class="container">

          <div class="form-group">
            <label for="check" class="col-lg-12 control-label"></label>
            <div class="col-lg-12 row">
              <div class="col-1">
                <input type="checkbox" id="check" name="check" [ngModel]=null ngModel #check="ngModel" [value]="check" required>
              </div>
              <div class="col-11">
                Hiermit bestätige ich/bestätigen wir die Anmeldung des/der oben genannten Teilnehmenden am Ruhrjamb des DPSG Diözesanverbands Essen. Die in dieser Anmeldung angegebenen personenbezogenen Daten, die allein zum Zwecke der Durchführung des entstehenden Vertragsverhältnisses notwendig und erforderlich sind, werden auf Grundlage gesetzlicher Berechtigungen erhoben. Ich/Wir bestätige/n zudem, dass eine DPSG-Mitgliedschaft vorliegt.<br />
              </div>

              <div *ngIf="check.errors?.required && check.dirty" class="alert alert-danger col-lg-12">
                Einverständniserklärung ist ein Pflichtfeld
              </div>
            </div>
          </div>
          </div>
          <div class="form-group">
            <div class="col-lg-10 col-lg-offset-2">
              <a [routerLink]="['/events/custom/ruhrjamb2024/registrations']" class="btn btn-default">Abbrechen</a>
              <button type="submit" class="btn btn-primary" [disabled]="addTNForm.invalid">Anlegen</button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>

