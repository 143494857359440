import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { SubscriptionService } from '../../../services/subscription.service';
import { NotifyService } from '../../../services/notify.service';
import { RegistrationService } from '../../../services/registration.service';
import { TemplatePageService } from '../../../services/template-page.service';
import { EventService } from '../../../services/event.service';
import { Settings } from '../../../classes/settings';

@Component({
  selector: 'app-events-registration-subscription-del',
  templateUrl: './events-registration-subscription-del.component.html',
  styleUrls: ['./events-registration-subscription-del.component.css']
})
export class EventsRegistrationSubscriptionDelComponent implements OnInit {
    id: any;
    params: any;
    event_id: any;
    registration_id: any;
    templatePage_id: any;
    subscription_id: any;
    event: any;
    templatePage: any;
    registration: any;
    backendurl: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private notifyService: NotifyService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private templatePageService: TemplatePageService,
    private router: Router
  ) { }

  deleteSubscription() {
    this.subscriptionService.deleteSubscription(this.event_id, this.registration_id, this.subscription_id).subscribe(
      data => {
        this.notifyService.snotifySuccess("Anmeldung erfolgreich gelöscht!")
        this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/']);
      },
      error => {
        this.notifyService.snotifyError("Beim Löschen der Anmeldung ist ein Fehler aufgetreten!")
        console.log(<any>error);
      });
    }

  ngOnInit() {

      this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
      this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);
      this.params = this.activatedRoute.params.subscribe(params => this.templatePage_id = params['templatePage_id']);
      this.params = this.activatedRoute.params.subscribe(params => this.subscription_id = params['subscription_id']);

      this.event = this.eventService.getEvent(this.event_id).subscribe(
        data => {
          this.event.title = data['title'];
          this.event.imageLink = data['imageLink'];
          this.event.active = data['active'];

          if(this.event.active == 0) {
            this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
            this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/' + this.templatePage_id]);
          }

        },
        error => console.log(<any>error)
      );

      this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
        data => {
          this.registration.title = data['title'];
          this.registration.template = data['template'];
          this.registration.description = data['description'];
          this.registration.active = data['active'];

          this.registrationService.isRegistrationClosed(this.event_id, this.registration_id).subscribe(
            data => {
              if (data == true) {
                this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
                this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/' + this.templatePage_id]);
              }
            }
          )

          if(this.registration.active == 0) {
            this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
            this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/' + this.templatePage_id]);
          }

        },
        error => console.log(<any>error)
      );


      this.templatePage = this.templatePageService.getTemplatePage(this.templatePage_id).subscribe(
        data => {
          this.templatePage.id = data['id'];
          this.templatePage.title = data['title'];
          this.templatePage.description = data['description'];
          this.templatePage.imageLink = data['imageLink'];

        },
        error => console.log(<any>error)
      );
    }

      ngOnDestroy() {
        this.params.unsubscribe();
      }
    }
