import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TemplateService} from '../../../services/template.service';
import {TemplatePageService} from '../../../services/template-page.service';
import { TemplatePage } from '../../../models/templatePage';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-template-dashboard',
  templateUrl: './admin-template-dashboard.component.html',
  styleUrls: ['./admin-template-dashboard.component.css']
})
export class AdminTemplateDashboardComponent implements OnInit {
  template: any;
  templatePages: Observable<TemplatePage[]>;

  params: any;
  template_id: any;
  status: any;
  message: any;
  isUsed: any;

  constructor(private activatedRoute: ActivatedRoute, private templateService: TemplateService, private templatePageService: TemplatePageService) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['id']);
    this.template = this.templateService.getTemplate(this.template_id).subscribe(
      data => {
        this.template.title = data['title'];
        this.templateService.isUsed(this.template_id).subscribe(
          data => {
            if (data == 1) {
              this.template.isUsed = true
            }
          }
        )
        
      },
    error => console.log(<any>error));
    this.templatePages = this.templatePageService.getTemplatePages(this.template_id);
  }

}
