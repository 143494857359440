import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { Settings } from '../classes/settings';
import 'rxjs/Rx';

@Injectable()
export class BezirkService {

  constructor(private http: HttpClient) { }

  getBezirke(): Observable<any>{
   return this.http.get(Settings.appURL + Settings.apiPath + 'public/bezirke')
    .map((response) => response);
  }

  getBezirk(bezirk_id: string): Observable<any>{
   return this.http.get(Settings.appURL + Settings.apiPath + 'public/bezirke/' + bezirk_id)
    .map((response) => response);
  }

}
