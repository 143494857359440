import { Component, OnInit } from '@angular/core';
import { User } from './models/user';
import { BackendService } from './services/backend.service';
import { UserService } from './services/user.service';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { AuthGuardService } from './services/auth.guard.service';
import { Router } from '@angular/router';
import { NotifyService } from './services/notify.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  user: Observable<User>;
  checkLogin: any;


  constructor(
    private backendService: BackendService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  logout(): void {
    this.backendService.logout()
    .subscribe(() => {
      location.reload();
      this.router.navigate(['/login']);
    });
  }

  ngOnInit(): void {
    this.userService.getActualUser().subscribe(user => {
      this.user = user
      if(user) {
        this.checkLogin = true
      }
    });
    this.userService.isUserCompleted().subscribe((completed: any) => {
      if(completed === false) {
        this.backendService.redirectUrl = '/user/changeData';
        this.router.navigate([this.backendService.redirectUrl]);
      }
    });

  }

}
