<div class="jumbotron row">
  <div class="display-4 col-12 col-sm-9">
      <h2 class="display-4">{{event.title | uppercase}}</h2>
    </div>
    <div class="col-12 col-sm-3">
      <img class="img-fluid" src="{{backendurl}}images/{{event.imageLink}}" alt="">
    </div>  </div>
<div class="col-md-12 col-md-offset-1">
  <div class="row row-cols-1 row-cols-md-3 justify-content-md-center">
    <div class="col mb-4" *ngFor="let registration of registrations | async" class="mx-auto">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">{{registration.title}} <span *ngIf="registration.free != 'no matter'" class="badge badge-dpsg">{{registration.free}} freie Plätze</span></h5>
          <a [routerLink]="[registration.id, 'subscription']" class="btn btn-primary col-12">Anmeldung</a>
        </div>
      </div>
    </div>
  </div>
</div>
