<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">Passwort ändern</h1>
    </div>
</div>
<div class="col-md-10 col-md-offset-1">
    <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
    <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
    <div class="well well bs-component">
        <form novalidate class="form-horizontal" (ngSubmit)="changePassword(changePasswordForm.value)" #changePasswordForm="ngForm">
            <fieldset>
                <div class="form-group">
                    <label for="oldpassword" class="col-lg-3 control-label">Altes Passwort</label>
                    <div class="col-lg-10">
                      <input type="password" class="form-control" id="oldpassword" name="oldpassword" placeholder="Passwort" ngModel #oldpassword="ngModel" minlength=6 required>
                      <div *ngIf="oldpassword.errors?.required && oldpassword.dirty || oldpassword.errors?.minlength && oldpassword.dirty" class="alert alert-danger">
                        Das Passwort muss mindestens 6 Zeichen lang sein.
          
                      </div>
                    </div>
                  </div>
                <div class="form-group">
                    <label for="newpassword" class="col-lg-3 control-label">Neues Passwort</label>
                    <div class="col-lg-10">
                      <input type="password" class="form-control" id="newpassword" name="newpassword" placeholder="Passwort" ngModel #newpassword="ngModel" minlength=6 required>
                      <div *ngIf="newpassword.errors?.required && newpassword.dirty || newpassword.errors?.minlength && newpassword.dirty" class="alert alert-danger">
                        Das Passwort muss mindestens 6 Zeichen lang sein.
          
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="newpassword_confirmation" class="col-lg-4 control-label">Neues Passwort wiederholen</label>
                    <div class="col-lg-10">
                      <input type="password" class="form-control" id="newpassword_confirmation" name="newpassword_confirmation" placeholder="Passwort wiederholen" ngModel #newpassword_confirmation="ngModel" required>
                      <div *ngIf="newpassword_confirmation.errors?.required && newpassword_confirmation.dirty" class="alert alert-danger">
                        Neues Passwort wiederholen ist ein Pflichtfeld
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-lg-10 col-lg-offset-2">
                      <a [routerLink]="['/']" class="btn btn-default">Abbrechen</a>
                      <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid">Ändern</button>
                    </div>
                  </div>
            </fieldset>
        </form>
    </div>
</div>