
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from '../models/subscription';
import { BackendService } from '../services/backend.service';
import { Settings } from '../classes/settings';

@Injectable()
export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addSubscription(event_id: string, registration_id: string, subscription: Object): Observable<Subscription[]> {
    return this.backendService.post('events/' + event_id + '/registrations/' + registration_id + '/subscriptions/', subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

  checkinSubscriptionKlebenBleiben(checkin: Object): Observable<Subscription[]> {
    return this.backendService.post('subscriptionsKlebenBleibenCheck', checkin)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

  getSubscriptions(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/subscriptions')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenGroups(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/subscriptionsKlebenBleibenGroups')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenTns(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/subscriptionsKlebenBleibenTns')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenStufen(event_id: string, anmeldende: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/anmeldende/' + anmeldende + '/subscriptionsKlebenBleibenStufen')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenStufenTns(event_id: string, anmeldende: string, stufe: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/anmeldende/' + anmeldende + '/stufen/' + stufe + '/subscriptionsKlebenBleibenStufenTns')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenAnmeldendeTns(event_id: string, anmeldende: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/anmeldende/' + anmeldende + '/subscriptionsKlebenBleibenAnmeldendeTns')
       .map((response) => response);
  }

  invoiceKlebenBleibenTns(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/invoiceKlebenBleibenTns', "", "zip")
       .map((response) => response);
  }

  checkinKlebenBleibenTns(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/checkinKlebenBleibenTns', "", "pdf")
       .map((response) => response);
  }
  rdpKlebenBleibenTns(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/rdpKlebenBleibenTns', "", "pdf")
       .map((response) => response);
  }
  rdp(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/rdp', "", "pdf")
       .map((response) => response);
  }
  rdpKlebenBleibenStaffs(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/rdpKlebenBleibenStaffs', "", "pdf")
       .map((response) => response);
  }

  calcKlebenBleibenBusfahrten(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/calcKlebenBleibenBusfahrten', "", "csv")
       .map((response) => response);
  }

  fotoerlaubnisKlebenBleibenTns(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.download('events/' +  event_id + '/registrations/' + registration_id + '/fotoerlaubnisKlebenBleibenTns', "", "csv")
       .map((response) => response);
  }

  invoiceKlebenBleibenStaffs(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/invoiceKlebenBleibenStaffs')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenAnmeldende(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/subscriptionsKlebenBleibenAnmeldende')
       .map((response) => response);
  }
  getSubscriptionsKlebenBleibenStaVes(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/subscriptionsKlebenBleibenStaVes')
       .map((response) => response);
  }

  getSubscriptionsKlebenBleibenStaffs(event_id: string, registration_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations/' + registration_id + '/subscriptionsKlebenBleibenStaffs')
       .map((response) => response);
  }

  getSubscriptionsOfUser(event_id: string, registration_id: string, templatePage_id:string, user_id: string): Observable<any>{
    return this.backendService.get('events/' + event_id + '/registrations/' + registration_id + '/subscriptionsOfUser/' + templatePage_id + '/user/' + user_id)
       .map((response) => response);
  }

  getSubscriptionOfUser(event_id: string, registration_id: string, templatePage_id:string, user_id: string): Observable<any>{
    return this.backendService.get('events/' + event_id + '/registrations/' + registration_id + '/subscriptionOfUser/' + templatePage_id + '/user/' + user_id)
       .map((response) => response);
  }

  getTNsOfUserAndParent(event_id: string, registration_id: string, templatePage_id:string, user_id: string, subscription_id: string): Observable<any>{
    return this.backendService.get('events/' + event_id + '/registrations/' + registration_id + '/tnsOfUserAndParent/' + templatePage_id + '/user/' + user_id + '/subscription/' + subscription_id)
       .map((response) => response);
  }

  getSubscription(event_id: string, registration_id: string, templatePage_id:string, user_id:string, subscription_id: string): Observable<any> {
    return this.backendService.get('events/' + event_id + '/registrations/' + registration_id + '/subscriptions/' + templatePage_id + '/user/' + user_id + '/subscription/' + subscription_id)
       .map((response) => response);
  }

  updateSubscription(event_id: string, subscription: Object): Observable<Subscription[]> {
   const apiUrl = 'events';
   const url = `${apiUrl}/${event_id}/registrations/${subscription['registration']}/subscriptions/${subscription['id']}`;
   return this.backendService.put(url, subscription)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

 deleteSubscription(event_id: string, registration:string, subscription: string): Observable<any> {
   const apiUrl = 'events';
   const url = `${apiUrl}/${event_id}/registrations/${registration}/subscriptions/${subscription}`;
   return this.backendService.delete(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
}

}
