<div class="jumbotron row">
  <div class="display-4 col-12 col-sm-9">
      <h1 class="display-4">{{registration.title | uppercase}}</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <div class="col-12 col-sm-3">
      <img class="img-fluid" src="{{backendurl}}images/{{event.imageLink}}" alt="">
    </div>  </div>
<div class="alert alert-danger" role="alert">
Bist du dir sicher, dass du diesen Eintrag löschen möchtest?<br />
<a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription']" class="btn btn-outline-danger">Nein</a>
<button type="button" class="btn btn-danger mx-2" (click)="deleteSubscription()">Ja</button>
</div>
