import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from '../models/subscription';
import { BackendService } from '../services/backend.service';
import { Settings } from '../classes/settings';

@Injectable({
  providedIn: 'root'
})
export class RoverDu2023Service {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addSubscription(subscription: Object): Observable<Subscription[]> {
    return this.backendService.post('events/custom/rover_du_2023/group/', subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

  addTN(subscription: Object): Observable<Subscription[]> {
    return this.backendService.post('events/custom/rover_du_2023/tn/', subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

  deleteTN(subscription: string): Observable<any> {
    return this.backendService.delete('events/custom/rover_du_2023/tn/' + subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
 }
 getGroups(): Observable<any>{
  return this.backendService.get('events/custom/rover_du_2023/groups')
     .map((response) => response);
}

  getStaves(): Observable<any>{
    return this.backendService.get('events/custom/rover_du_2023/staves')
      .map((response) => response);
  }

  getSubscription(): Observable<any> {
    return this.backendService.get('events/custom/rover_du_2023/group/')
       .map((response) => response);
  }

  getTN(subscription_id): Observable<any> {
    return this.backendService.get('events/custom/rover_du_2023/tn/' + subscription_id)
       .map((response) => response);
  }

  getTNsofUser(): Observable<any>{
    return this.backendService.get('events/custom/rover_du_2023/tnsofuser')
       .map((response) => response);
  }

  getTNs(): Observable<any>{
    return this.backendService.get('events/custom/rover_du_2023/tns')
       .map((response) => response);
  }
   
  updateSubscription(subscription: Object): Observable<Subscription[]> {
    const url = `events/custom/rover_du_2023/group/`;
    return this.backendService.put(url, subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

  rdp(): Observable<any>{
    return this.backendService.download('events/custom/rover_du_2023/rdp', "", "pdf")
       .map((response) => response);
  }

   updateTN(subscription: Object): Observable<Subscription[]> {
    const url = `events/custom/rover_du_2023/tn/`;
    return this.backendService.put(url, subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }
   
}
