<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">Passwort zurücksetzen</h1>
    </div>
  </div>
  <div class="mt-4 col-lg-6 offset-lg-3">
    <div class="card">
      <div class="card-body">
        <form (ngSubmit)="forgotPassword(formularwerte)" [formGroup]="formularwerte">
      <div class="form-group col-lg-12">
        <label for="email" class="col-sm-4 col-form-label">E-Mail-Adresse</label>
        <div class="col-sm-12">
          <input autofocus type="email" name="email" class="form-control" id="email" placeholder="E-Mail-Adresse" formControlName="email" required>
        </div>
      </div>
      <div class="form-group col-lg-12">
        <div class="col-lg-12">
          <button type="submit" class="btn btn-primary my-3 col-12 float-left col-sm-5" [disabled]="!formularwerte.valid">Zurücksetzen</button>
          <a [routerLink]="['/']" class="btn btn-outline-secondary my-3 float-right col-12 col-sm-5">Abbrechen</a>  
        </div>
      </div>
    </form>
  </div>
    </div>
  </div>
