import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationService} from '../../../../services/registration.service';
import {RegistrationTypeService} from '../../../../services/registration-type.service';
import { Registration } from '../../../../models/registration';
import { RegistrationType } from '../../../../models/registrationType';
import { Template } from '../../../../models/template';
import {TemplateService} from '../../../../services/template.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-event-registration-edit',
  templateUrl: './admin-event-registration-edit.component.html',
  styleUrls: ['./admin-event-registration-edit.component.css']
})
export class AdminEventRegistrationEditComponent implements OnInit {

  params: any;
  event_id: any;
  registration_id: any;
  registration: any;
  status: any;
  message: any;
  registrationTypes:  Observable<RegistrationType[]>;
  templates:  Observable<Template[]>;


  constructor(
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private registrationTypeService: RegistrationTypeService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['id']);
    this.registrationTypes = this.registrationTypeService.getRegistrationTypes();
    this.templates = this.templateService.getTemplates();
   this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
     data => {
       this.registration.description = data['description'];
       this.registration.title = data['title'];
       this.registration.maxNumber = data['maxNumber'];
       this.registration.id = data['id'];
       this.registration.active = data['active'];
       this.registration.type = data['type'];
       this.registration.template = data['template'];
       this.registration.closingDate = data['closingDate'];
       this.registration.maxTNs = data['maxTNs'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateRegistration(registration) {
   this.registrationService.updateRegistration(this.event_id, registration)
   .subscribe(
     registration => {
       this.status = 'success';
       this.message = registration['message'];
       this.router.navigate(['/admin/event/', this.event_id, 'registrations']);
     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
