<div class="jumbotron">
  <h1 class="display-4">{{event.title}}</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <!--TODO: STATISTIKEN ALLER SUBSCRITIONS EINFÜGEN -->
  <div>
    <a [routerLink]="['/admin/events']" class="btn btn-secondary">Zurück</a>
    <a [routerLink]="['/admin/event/', event.id, 'registration', 'create']" class="btn btn-success mx-2">Neue Anmeldung anlegen</a>
  <!--  <a [routerLink]="['/admin/event/', event.id, 'checkin']" class="btn btn-dark"> Checkin</a>-->
  </div>
</div>
<br />
<div class="col-md-12 col-md-offset-1">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Anmeldung</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let registration of registrations | async">
          <td>{{registration.title}} </td>
          <td>
            <a [routerLink]="['/admin/event/', event.id, 'registration', registration.id, 'subscriptions']" class="btn mx-1 btn-info"> <i class="fa fa-eye"></i></a>
            <a [routerLink]="['/admin/event/', event.id, 'registration', 'edit', registration.id]" class="btn mx-1 btn-warning"> <i class="fa fa-pencil"></i></a>
            <a [routerLink]="['/admin/event/', event.id, 'registration', 'delete', registration.id]" class="btn mx-1 btn-danger"> <i class="fa fa-trash"></i></a>
            <a [routerLink]="['/admin/event/', event.id, 'registration', 'roles', registration.id]" class="btn mx-1 btn-dark disabled"> <i class="fa fa-key"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
