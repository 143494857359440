<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Vorlage kopieren</h1>
    <h2 class="display-6">{{template.title | uppercase}}</h2>
  </div>
</div>
<div class="alert alert-success" role="alert">
Soll diese Vorlage kopiert werden?<br />
<a [routerLink]="['/admin/template', template_id]" class="btn btn-outline-success">Nein</a>
<button type="button" class="btn btn-success" (click)="copyTemplate()">Ja</button>
</div>
