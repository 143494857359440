import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../../services/registration.service';
import { Registration } from '../../../../models/registration';
import { EventService } from '../../../../services/event.service';
import { BezirkService } from '../../../../services/bezirk.service';
import { StammService } from '../../../../services/stamm.service';
import { TemplatePageService } from '../../../../services/template-page.service';
import { SubscriptionService } from '../../../../services/subscription.service';
import { UserService } from '../../../../services/user.service';
import {Observable} from 'rxjs';
import { User } from '../../../../models/user';
import { Router} from '@angular/router';
import { Subscription } from '../../../../models/subscription';
import { NotifyService } from '../../../../services/notify.service';
import { Ruhrjamb2024Service } from '../../../../services/ruhrjamb2024.service';


@Component({
  selector: 'app-ruhrjamb2024-add-staff',
  templateUrl: './ruhrjamb2024-add-staff.component.html',
  styleUrls: ['./ruhrjamb2024-add-staff.component.css']
})
export class Ruhrjamb2024AddStaffComponent implements OnInit {

  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  istGeschwister: boolean;
  staff: any;

  lebensmUnvertOptions = [
    { name: 'Senf', selected: false },
    { name: 'Sellerie', selected: false },
    { name: 'Eier', selected: false },
    { name: 'Gluten', selected: false },    
    { name: 'Milchprodukte (alles aus Milch, Butter etc.)', selected: false },
    { name: 'Nüsse', selected: false },
    { name: 'Laktose', selected: false },
    { name: 'Fructose', selected: false },
    { name: 'Soja', selected: false},
  ]

  lebensmVerpfOptions = [
    { name: 'Vegan', selected: false },
  ]

  tageOptions = [
    { name: 'Donnerstag', selected: false },
    { name: 'Übernachtung Donnerstag auf Freitag', selected: false },
    { name: 'Freitag', selected: false },
    { name: 'Übernachtung Freitag auf Samstag', selected: false },
    { name: 'Samstag', selected: false },
    { name: 'Übernachtung Samstag auf Sonntag', selected: false },
    { name: 'Sonntag', selected: false },
  ]

  fuehrerscheinOptions = [
    { name: 'Gabelstapler', selected: false },
    { name: 'LKW (C, CE)', selected: false },
    { name: 'Bus', selected: false },
    { name: 'Anhänger', selected: false },
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private bezirkService: BezirkService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private stammService: StammService,
    private subscriptionService: SubscriptionService,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router,
    private Ruhrjamb2024Service: Ruhrjamb2024Service,
    private notifyService: NotifyService
  ) { }

  addStaff(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.subscription.data.tage = JSON.stringify(this.tageOptions)
    this.subscription.data.fuehrerschein = JSON.stringify(this.fuehrerscheinOptions)
    this.Ruhrjamb2024Service.addStaff(this.subscription)
    .subscribe(
      subscription => {
        this.notifyService.snotifySuccess("Staff erfolgreich angelegt!")
        this.router.navigate(['/']);
      },
      error => {
        this.notifyService.snotifyError("Beim Anlegen des Teilnehmers ist ein Fehler aufgetreten!")
        console.log(<any>error)
      }
    );
  }

  updateCheckedlebensmUnvert(option, event) {
    for (var i = 0; i < this.lebensmUnvertOptions.length; i++){
      if (this.lebensmUnvertOptions[i].name == option){
        this.lebensmUnvertOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedlebensmVerpf(option, event) {
    for (var i = 0; i < this.lebensmVerpfOptions.length; i++){
      if (this.lebensmVerpfOptions[i].name == option){
        this.lebensmVerpfOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedtage(option, event) {
    for (var i = 0; i < this.tageOptions.length; i++){
      if (this.tageOptions[i].name == option){
        this.tageOptions[i].selected = event.target.checked;
      }
    }
  }

  updateFuehrerschein(option, event) {
    for (var i = 0; i < this.fuehrerscheinOptions.length; i++){
      if (this.fuehrerscheinOptions[i].name == option){
        this.fuehrerscheinOptions[i].selected = event.target.checked;
      }
    }
  }

  ngOnInit(): void {
    this.staff = new Subscription
    this.event = new Array()
    this.event.title = "Ruhrjamb 2024"
    this.event.imageLink = "assets/images/ruhrjamb2024.png";
    this.event.active = 0;
this.user = this.userService.getActualUser().subscribe(
  user => {
  this.user = user;
  this.user.id = this.user['id'];
   this.user.surname = this.user['surname'];
   this.user.givenname = this.user['givenname'];
   this.user.email = this.user['email'];
   this.user.stamm = this.user['stamm'];

   this.staff = this.Ruhrjamb2024Service.getStaff().subscribe(
    data => {
      this.staff = data;
      this.subscription_id = this.staff['id'];
      this.lebensmUnvertOptions = JSON.parse(data['lebensmUnvert'])
      this.lebensmVerpfOptions = JSON.parse(data['lebensmVerpf'])
      this.tageOptions = JSON.parse(data['tage'])
      this.fuehrerscheinOptions = JSON.parse(data['fuehrerschein'])
    },
    error => {
      this.staff.status = null
      this.staff.gesundheitszeugnis = null
      this.staff.elektrofachkraft = null
      this.staff.fernpilotenzeugnis = null
      this.staff.foto = null
      this.staff.video = null
      this.staff.kochen_essen = null
      this.staff.zeltauf_abbau = null
      this.staff.workshop = null
      this.staff.buehnenauf_abbau = null
      this.staff.fotografieren = null
      this.staff.filmische_doku = null
      this.staff.videoschnitt = null
      this.staff.springer_in = null
      this.staff.toilettendienst = null
      this.staff.maskottchen = null
      this.staff.ordner_in = null
      this.staff.nachtwache = null
      this.staff.finalized = 0
      this.staff.id = 0
      this.notifyService.snotifyWarning("Es ist keine Anmeldung mehr möglich!")
      this.router.navigate(['/events']);
    }
  );

});
}

}


