<div class="jumbotron">
  <div>
    <h1 class="display-4">Aktualisiere: {{event.title}}</h1>
    <img src="{{backendurl}}images/{{event.imageLink}}"/>
  </div>
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="updateEvent(event)" #editEventForm="ngForm">
      <fieldset>
        <legend></legend> 
        <div class="form-group">
          <label for="title" class="col-lg-2 control-label">Veranstaltungsname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="title" name="title" placeholder="Veranstaltungsname" [(ngModel)]="event.title" #title="ngModel" required>
            <div *ngIf="title.errors?.required && title.dirty" class="alert alert-danger">
              Veranstaltungsname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Beschreibung</label>
          <div class="col-lg-10">
            <editor id="description" name="description" [(ngModel)]="event.description" #description="ngModel" [init]="{
        base_url: '/tinymce',
        suffix: '.min',
        plugins: 'autoresize lists',
        toolbar: 'undo redo | bold italic underline | bullist numlist',
        menubar: false,
        statusbar: false,
        branding: false,
        placeholder:
        'Information...'
    }"></editor>
          </div>
          <div class="alert alert-light">
            Diese Beschreibung wird auf der Startseite bzw. dem Event-Dashboard angezeigt.
          </div>
        </div>
        <div class="form-group">
          <label for="imageLink" class="col-lg-2 control-label">Bild austauschen</label>
          <div class="col-lg-10">
            <input type="file" class="form-control-file" id="imageLink" name="imageLink" placeholder="Bild-URL" ngModel #imageLink="ngModel" (change)="fileEvent($event)" accept=".jpg,.gif,.png">
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Status</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="active" name="active" [(ngModel)]="event.active" #active="ngModel">
              <option [selected]="event.active == 1" [value]="1">Aktiv</option>
              <option [selected]="event.active == 0" [value]="0">Inaktiv</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/admin/events']" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="editEventForm.invalid">Aktualisieren</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
