import { Component, OnInit, Input } from '@angular/core';
import { Router} from '@angular/router';
import { Subscription } from '../../../../models/subscription';
import { NotifyService } from '../../../../services/notify.service';
import { Ruhrjamb2024Service } from '../../../../services/ruhrjamb2024.service';


@Component({
  selector: 'app-ruhrjamb2024-add-tn',
  templateUrl: './ruhrjamb2024-add-tn.component.html',
  styleUrls: ['./ruhrjamb2024-add-tn.component.css']
})
export class Ruhrjamb2024AddTNComponent implements OnInit {

  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  istGeschwister: boolean;

  lebensmUnvertOptions = [
    { name: 'Senf', selected: false },
    { name: 'Sellerie', selected: false },
    { name: 'Eier', selected: false },
    { name: 'Gluten', selected: false },    
    { name: 'Milchprodukte (alles aus Milch, Butter etc.)', selected: false },
    { name: 'Nüsse', selected: false },
    { name: 'Laktose', selected: false },
    { name: 'Fructose', selected: false },
    { name: 'Soja', selected: false},
  ]

  lebensmVerpfOptions = [
    { name: 'Vegan', selected: false },
  ]

  constructor(
    private router: Router,
    private ruhrjamb2024Service: Ruhrjamb2024Service,
    private notifyService: NotifyService
  ) { }

  addTN(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.ruhrjamb2024Service.addTN(this.subscription)
    .subscribe(
      subscription => {
        this.notifyService.snotifySuccess("Teilnehmer erfolgreich angelegt!")
        this.router.navigate(['/events/custom/ruhrjamb2024/registrations/']);
      },
      error => {
        this.notifyService.snotifyError("Beim Anlegen des Teilnehmers ist ein Fehler aufgetreten!")
        console.log(<any>error)
      }
    );
  }

  updateCheckedlebensmUnvert(option, event) {
    for (var i = 0; i < this.lebensmUnvertOptions.length; i++){
      if (this.lebensmUnvertOptions[i].name == option){
        this.lebensmUnvertOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedlebensmVerp(option, event) {
    for (var i = 0; i < this.lebensmVerpfOptions.length; i++){
      if (this.lebensmVerpfOptions[i].name == option){
        this.lebensmVerpfOptions[i].selected = event.target.checked;
      }
    }
  }

  ngOnInit(): void {
    this.ruhrjamb2024Service.getFreeSeats().subscribe(
      data => {
        if (data <= 0) {
          this.notifyService.snotifyError("Es sind alle Plätze belegt!")
          this.router.navigate(['/events/custom/ruhrjamb2024/registrations/']);
        }
      }
    )

    this.event = new Array()
    this.event.title = "Ruhrjamb 2024"
    this.event.imageLink = "assets/images/ruhrjamb2024.png";
    this.event.active = 0;

  }

}
